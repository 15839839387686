import React, { useEffect, useState } from 'react';
import { Container, Row, Col, Card, Button, Dropdown, Spinner } from 'react-bootstrap';
import axios from 'axios';
import './MyAccountPage.css';
import { useLogin } from '../UserAutentication/LoginProvider';
import {Link, useNavigate} from 'react-router-dom';

const MyAccountPage = () => {
    const navigate = useNavigate();
    const { isLoggedIn } = useLogin();
    const [MyAccountData, setMyAccountData] = useState(null);
    const [hasSubscription, setHasSubscription] = useState(false);
    const [isLoading, setIsLoading] = useState(false); // New loading state

    const fetchMyAccountData = async () => {
        setIsLoading(true); // Start loading
        try {
            const response = await axios.get(process.env.REACT_APP_BASE_SERVER_URL+'/api/v1/MyAccount/getAccountInfo', {
                withCredentials: true,
                params: { isLoggedIn }
            });

            if (response.status === 200) {
                setHasSubscription(true);
                setMyAccountData(response.data);
            } else {
                console.warn('getAccountInfo, Unexpected response status:', response.status);
            }

        } catch (error) {
                console.error('unexpected error fetching user data, AccountId ' + isLoggedIn);
        } finally {
            setIsLoading(false); // End loading
        }
    };


    useEffect(() => {
        if (isLoggedIn) {
            fetchMyAccountData();
        }
    }, [isLoggedIn]);

    const handleCancelSubscription = async () => {
        setIsLoading(true); // Start loading
        const subscriptionId = MyAccountData.subscriptionPaypalId;
        try {
            const response = await axios.post(process.env.REACT_APP_BASE_SERVER_URL+`/api/v1/cancel-subscription`, { subscriptionId }, {
                withCredentials: true
            });
            if (response.status === 200) {
                fetchMyAccountData(); // Refresh account data after cancelling subscription
            } else {
                console.error('Error cancelling subscription accountId: ' + isLoggedIn);
            }
        } catch (error) {
            console.error('Error cancelling subscription accountId: ' + isLoggedIn);
        } finally {
            setIsLoading(false); // End loading
        }
    };

    const handleLogout = () => {
        navigate('/logout');
    };

    const handleContactUs = () => {
        navigate('/Help-Center');
    };

    if (isLoading) {
        return (
            <div className="d-flex justify-content-center align-items-center" style={{ height: '100vh' }}>
                <Spinner animation="border" role="status" className="spinner-border">
                    <span className="visually-hidden">Loading...</span>
                </Spinner>
            </div>
        );
    }

    if (!MyAccountData) {
        return <div>fail to load your account info, please try again later</div>;
    }

    return (
        <Container fluid className="my-account-page">
            <Row className="justify-content-center align-items-center">
                <Col md={8}>
                    <Card className="text-left shadow-lg p-4 bg-dark text-white rounded">
                        <Row>
                            <Col md={8}>
                                <Card.Body>
                                    <Card.Title className="display-4 mb-3">👤 My Account</Card.Title>
                                    <Card.Text>
                                        <strong>AccountId:</strong> {MyAccountData.accountId}
                                    </Card.Text>
                                    <Card.Text>
                                        <strong>Name:</strong> {MyAccountData.name}
                                    </Card.Text>
                                    <Card.Text>
                                        <strong>Email:</strong> {MyAccountData.email}
                                    </Card.Text>
                                    <Card.Text>
                                        <strong>Signup Date:</strong> {MyAccountData.created.substring(0, 10)}
                                    </Card.Text>

                                    {MyAccountData.billingMode && (
                                        <>
                                            <Card.Text>
                                                <strong>Current Subscription:</strong> {MyAccountData.productName}
                                            </Card.Text>
                                            <Card.Text>
                                                <strong>Billing Mode:</strong> {MyAccountData.billingMode}
                                            </Card.Text>
                                            <Card.Text>
                                                <strong>Subscription Status: </strong>
                                                <span style={{ color: MyAccountData.status === 'Cancelled' ? 'red' : 'inherit' }}>
                                                    {MyAccountData.status}
                                                </span>
                                            </Card.Text>
                                            {MyAccountData.nextBillingDate && (
                                                <Card.Text>
                                                    <strong>Next Billing Date:</strong> {MyAccountData.nextBillingDate.substring(0, 10)}
                                                </Card.Text>
                                            )}
                                        </>

                                    )}
                                    <Card.Text>
                                        <strong>AI Credits Remaining:</strong> {MyAccountData.creditAmount !== null ? MyAccountData.creditAmount : 0}
                                    </Card.Text>
                                    <div className="d-flex flex-column flex-md-row justify-content-center align-items-center">
                                        {isLoggedIn && (
                                            <Button
                                                variant="secondary"
                                                className="account-actions-button mb-2 mb-md-0 ml-md-3"
                                                onClick={handleLogout}
                                            >
                                                Logout
                                            </Button>
                                        )}
                                        <Button variant="secondary" className="account-actions-button ml-md-3" onClick={handleContactUs}>
                                            Contact Us
                                        </Button>
                                        {MyAccountData.nextBillingDate && (
                                            <Dropdown className="account-actions-button mb-2 mb-md-0 ml-md-3">
                                                <Dropdown.Toggle variant="secondary" id="dropdown-basic">
                                                    More
                                                </Dropdown.Toggle>
                                                <Dropdown.Menu>
                                                    <Dropdown.Item onClick={handleCancelSubscription}>
                                                        Cancel Next Recurring
                                                    </Dropdown.Item>
                                                </Dropdown.Menu>
                                            </Dropdown>
                                        )}
                                    </div>
                                </Card.Body>
                            </Col>
                            <Col md={4} className="d-none d-md-block">
                                <img
                                    src={MyAccountData.profilePhoto !== null ? MyAccountData.profilePhoto : "https://ai-tattoo-website-images.s3.amazonaws.com/Myaccount-new.png"}
                                    width="200"
                                    height="200"
                                    alt="TattooGPT User"
                                    className="img-fluid"
                                    loading="lazy"
                                />
                            </Col>
                        </Row>
                    </Card>
                </Col>
                <Col md={4} className="d-none d-md-block">
                    <img
                        src="https://ai-tattoo-website-images.s3.amazonaws.com/MyAccount-backgound1.jpg"
                        alt="Side Image"
                        className="side-image"
                    />
                </Col>
            </Row>
        </Container>
    );
};

export default MyAccountPage;
