import React, {useState, useEffect} from 'react';
import axios from 'axios';
import './OneTimePaywallPage.css';
import CheckoutPage from "../CheckoutPage/CheckoutPage";
import {useLogin} from "../UserAutentication/LoginProvider";

const OneTimePaywallPage = ({title = null, ImageId=null}) => {
    const {isLoggedIn} = useLogin();
    const [selectedPlan, setSelectedPlan] = useState('Pro'); // Default to Pro plan
    const [products, setProducts] = useState([]);
    const [selectedProduct, setSelectedProduct] = useState(null);
    const [isLoading, setIsLoading] = useState(true); // Add loading state
    const PaywallTitle = title == null ? "Get More AI Credits!" : title;

    const plans = [
        {
            name: 'Starter',
            color: '#d5be3c',
            credits: 20,
            productId: 11
        },
        {
            name: 'Pro',
            color: '#33cc1d',
            credits: 120,
            productId: 14
        },
        {
            name: 'Premium',
            color: 'blue',
            credits: 500,
            productId: 12,
        },
    ];

    useEffect(() => {
        fetchProducts();
    }, []);

    useEffect(() => {
        if (products.length > 0) {
            const productId = getProductId();
            const product = getProductById(productId);
            setSelectedProduct(product);
            setIsLoading(false); // Stop loading when products are set
        }
    }, [products, selectedPlan]);

    const fetchProducts = async () => {
        console.log("Fetching products from the server");
        try {
            const response = await axios.get(process.env.REACT_APP_BASE_SERVER_URL + `/api/v1/products/getProducts`, {
                withCredentials: true
            });
            if (response.status === 200) {
                setProducts(response.data);
            }
        } catch (error) {
            console.error('Error fetching Products data:', error);
        }
    };

    const getProductById = (productId) => {
        return products.find(product => product.productId === productId);
    };

    const getProductId = () => {
        const plan = plans.find(p => p.name === selectedPlan);
        return plan.productId;
    };

    const renderPrice = (productId) => {
        const product = getProductById(productId);
        if (!product) return null;

        return (
            <>
                {product.msrpPrice !== product.finalPrice && (
                    <span className="strikethrough">${product.msrpPrice}</span>
                )}
                ${product.finalPrice}
            </>
        );
    };

    const selectedPlanInfo = plans.find(plan => plan.name === selectedPlan);

    return (
        <div className="paywall">
            <h1 className="paywall-main-title">{PaywallTitle}</h1>

            <div className="plan-switch">
                {plans.map(plan => (
                    <div
                        key={plan.name}
                        className={`switch-button ${selectedPlan === plan.name ? 'selected' : ''}`}
                        onClick={() => setSelectedPlan(plan.name)}
                    >
                        {plan.name}
                    </div>
                ))}
            </div>

            <div className="plans">
                <div
                    className={`plan ${selectedPlan.toLowerCase()} ${selectedPlan === selectedPlan ? 'selected' : ''}`}>
                    <ul>
                        <li className="first-paywall-benefit">
                            ✅ Create
                            <span style={{
                                color: selectedPlanInfo.color,
                                fontWeight: 'bold',
                                fontSize: 20
                            }}>{' ' + selectedPlanInfo.credits + ' '}</span>
                            Tattoo AI Designs
                        </li>
                        <li>✅ Control Tattoo Advanced complexity</li>
                        <li>✅ Access all Special ink Styles</li>
                        <li>✅ Best Resolution for Tattoo Downloads</li>
                        <li>✅ Unlimited access to your Gallery</li>
                        <li>✅ Up to 2 Tattoos in parallel</li>
                        <li className="paywall-last-benefit">✅ Enjoy new features and Updates</li>
                    </ul>
                </div>
            </div>

            <div className="total-price">
                <h3>
                    {renderPrice(getProductId())}
                </h3>
            </div>

            <div className="paywall-checkout-buttons">
                {!isLoading && selectedProduct && (
                    <CheckoutPage key={`${selectedPlan}`} product={selectedProduct}/>
                )}
            </div>

        </div>
    );
};

export default OneTimePaywallPage;
