import React, { useEffect, useState } from 'react';
import { Container } from 'react-bootstrap';
import './RecoveryPage.css';
import CheckoutPage from '../CheckoutPage/CheckoutPage';
import { useLogin } from '../UserAutentication/LoginProvider';
import { useNavigate } from "react-router-dom";
import GoogleLoginButtonLogic from "../UserAutentication/GoogleLoginButtonLogic";

const howItWorkImageUrls = [
    'https://ai-tattoo-website-images.s3.amazonaws.com/Screenshot+2024-10-19+at+11.38.27.png',
    'https://ai-tattoo-website-images.s3.amazonaws.com/Screenshot+2024-10-19+at+11.39.55.png',
    'https://ai-tattoo-website-images.s3.amazonaws.com/Screenshot+2024-10-19+at+11.40.46.png',
    'https://ai-tattoo-website-images.s3.amazonaws.com/Screenshot+2024-10-19+at+11.43.40.png',
    'https://ai-tattoo-website-images.s3.amazonaws.com/Screenshot+2024-10-19+at+11.40.46.png',
    'https://ai-tattoo-website-images.s3.amazonaws.com/Screenshot+2024-10-19+at+11.45.03.png',
    'https://ai-tattoo-website-images.s3.amazonaws.com/Screenshot+2024-10-19+at+11.46.36.png',
];

const RecoveryPage = () => {
    const navigate = useNavigate();
    const { hasActiveSubscription, isLoggedIn } = useLogin();
    const [currentImageIndex, setCurrentImageIndex] = useState(0);

    useEffect(() => {
        const intervalId = setInterval(() => {
            setCurrentImageIndex((prevIndex) => (prevIndex + 1) % howItWorkImageUrls.length);
        }, 2000); // Change every 2 seconds

        return () => clearInterval(intervalId);
    }, []);

    const product = { productId: 13, planType: 'one-time', finalPrice: 5 };

    if (hasActiveSubscription) {
        navigate('/');
        return null;
    }

    return (
        <>
            <Container fluid className="recovery-page">
                <div className="content-container">
                    <h1 className="title">🔥 Limited-Time Black Friday Deal!</h1>
                    <img
                        src={howItWorkImageUrls[currentImageIndex]}
                        alt="TattooGPT Design"
                        className="recovery-image"
                    />
                    <h2 className="offer-text">🎨 100 Custom AI-Generated Tattoo Designs</h2>
                    <h3 className="highlight"><span className="price">Only $5  🎉</span></h3>
                    <p className="one-time-payment">💸 No Subscriptions, No Hassle – Pay Once & Use whenever!</p>
                    <p className="description">
                        🚀 Transform your tattoo ideas into stunning, professional designs in seconds! Perfect for tattoo lovers or artists. 🖌️✨
                    </p>

                    {!isLoggedIn && (
                        <div className="recovery-login">
                            <GoogleLoginButtonLogic />
                        </div>
                    )}

                    {isLoggedIn && (
                        <div className="checkout-buttons-recovery-container">
                            <CheckoutPage product={product} />
                        </div>
                    )}

                    <p className="offer-expiry">⏳ Hurry – Offer Ends Soon!</p>
                </div>
            </Container>
        </>
    );
};

export default RecoveryPage;
