import React, {useEffect} from 'react';
import {useParams, useNavigate} from 'react-router-dom';
import {TwitterShareButton, LinkedinShareButton, WhatsappShareButton} from 'react-share';
import './ViewTattooPage.css';

const ViewTattooPage = () => {
    const {userId, imageId} = useParams();
    const navigate = useNavigate();

    useEffect(() => {
        // Regular expression to match only numbers, letters, and hyphens
        const validPattern = /^[a-zA-Z0-9\-]+$/;

        // Validate userId and imageId to ensure they match the pattern
        if (!validPattern.test(userId) || !validPattern.test(imageId)) {
            navigate('/');
        }
    }, [userId, imageId, navigate]);

    const imageUrl = `https://ai-tattoo-s3-bucket.s3.amazonaws.com/${userId}/AI-Tattoo-Generated-image_${imageId}.jpg`;
    const shareUrl = `${window.location.origin}/viewTattoo/${userId}/${imageId}`;

    const handleGenerateClick = () => {
        navigate('/generate');
    };

    return (
        <div className="view-tattoo">
            <h1>AI Tattoo Generator</h1>
            <p className="description">
                This tattoo was generated by TattooGPT.Art. We invite you to create your own unique tattoo design!
            </p>
            <div className="share-buttons">
                <TwitterShareButton url={shareUrl} title="Check out this AI-generated tattoo from www.TattooGPT.Art!">
                    <button className="btn btn-info">
                        <i className="bi bi-twitter"></i> Share on Twitter
                    </button>
                </TwitterShareButton>
                <LinkedinShareButton url={shareUrl} title="Check out this AI-generated tattoo from www.TattooGPT.Art!">
                    <button className="btn btn-primary">
                        <i className="bi bi-linkedin"></i> Share on LinkedIn
                    </button>
                </LinkedinShareButton>
                <WhatsappShareButton url={shareUrl} title="Check out this AI-generated tattoo from www.TattooGPT.Art!">
                    <button className="btn btn-success">
                        <i className="bi bi-whatsapp"></i> Share on WhatsApp
                    </button>
                </WhatsappShareButton>
                {/* Add Instagram share button logic here*/}
            </div>
            <button className="generate-your-button" onClick={handleGenerateClick}>Generate Your Tattoo</button>
            <div className="image-container">
                <img src={imageUrl} alt="TattooGPT Generated Tattoo" className="tattoo-image"/>
            </div>

        </div>
    );
};


export default ViewTattooPage;

