import React from 'react';
import './TermsAndConditionsPage.css';

// TermsAndConditionsPage
const TermsAndConditionsPage = () => {
    return (
        <div className="terms">
            <h1>Terms and Conditions</h1>

            <section className="terms-section">
                <h4>1. Introduction</h4>
                <p>Welcome to TattooGPT.Art. By accessing or using our services, you confirm that you accept these Terms and Conditions and agree to comply with them. If you do not agree, please refrain from using our services. These terms govern your use of TattooGPT.Art’s website, including our AI-generated tattoo designs, subscription plans, and all related offerings.</p>
            </section>

            <section className="terms-section">
                <h4>2. Subscription Plans</h4>
                <p>We offer monthly and annual subscription plans that renew automatically. Each renewal provides AI credits to use for tattoo design generation, with each tattoo generation consuming one AI credit. You may cancel your subscription at any time; no further charges will apply after cancellation. If you exhaust your credits but still have an active subscription, you may opt for a one-time purchase of additional credits.</p>
            </section>

            <section className="terms-section">
                <h4>3. Privacy</h4>
                <p>We are committed to protecting your privacy. All generated tattoos and links are publicly accessible, but we do not store any personal data within the generated tattoos themselves. For more details, please review our Privacy Policy.</p>
            </section>

            <section className="terms-section">
                <h4>4. Content Restrictions</h4>
                <p>Our AI generator does not permit explicit content such as nudity or offensive language. Any attempts to generate such content will be blocked. We reserve the right to suspend or terminate accounts violating these terms.</p>
            </section>

            <section className="terms-section">
                <h4>5. Intellectual Property</h4>
                <p>The AI model used for tattoo generation is licensed from OpenAI. Tattoo designs generated through our service are for personal use only. We make no guarantee regarding the quality or aesthetic accuracy of these designs and disclaim liability for any issues resulting from their use.</p>
            </section>

            <section className="terms-section">
                <h4>6. Security and Unauthorized Access</h4>
                <p>We prioritize security and take appropriate measures to safeguard the platform. Any unauthorized attempts to breach our security will result in legal action. We reserve the right to cooperate with law enforcement to investigate any such incidents.</p>
            </section>

            <section className="terms-section">
                <h4>7. Service Availability</h4>
                <p>As a new service, occasional downtime may occur. We are dedicated to addressing and resolving any issues promptly, but we cannot guarantee uninterrupted access to our website or services.</p>
            </section>

            <section className="terms-section">
                <h4>8. Payment Processing</h4>
                <p>All payments are processed via PayPal. By using our services, you agree to PayPal's terms and conditions. No refunds will be issued for credits that have already been used. Unused credits may be refunded within a reasonable time, subject to PayPal’s refund policies.</p>
            </section>

            <section className="terms-section">
                <h4>9. Refund Policy</h4>
                <p>Due to the digital nature of our products, once credits have been used, they are non-refundable. Please evaluate our services before using credits to ensure satisfaction with our offerings.</p>
            </section>

            <section className="terms-section">
                <h4>10. Disclaimer of Warranties</h4>
                <p>Our services are provided “as is” and “as available” without warranties of any kind, either express or implied. We do not guarantee that the website will meet your requirements or be free from errors or harmful components.</p>
            </section>

            <section className="terms-section">
                <h4>11. Limitation of Liability</h4>
                <p>To the fullest extent permissible by applicable law, TattooGPT.Art shall not be liable for any direct, indirect, incidental, special, or consequential damages arising out of or related to your use of our website or services.</p>
            </section>

            <section className="terms-section">
                <h4>12. Amendments to Terms</h4>
                <p>TattooGPT.Art reserves the right to amend these terms at any time. We will notify users of significant changes by posting an updated version on our website, which will take effect immediately upon posting.</p>
            </section>

            <section className="terms-section">
                <h4>13. Governing Law</h4>
                <p>These terms are governed by and construed in accordance with the laws of the United States, the European Union, and Israel. By using our services, you agree to submit to the exclusive jurisdiction of these regions as applicable.</p>
            </section>

            <footer>
                <p>By accessing or using our website, you acknowledge that you have read, understood, and agree to be bound by these Terms and Conditions.</p>
                <p>---</p>
                <p><strong>TattooGPT.Art</strong></p>
                <p>www.TattooGPT.art</p>
                <p>TattooGPT.Team@Gmail.com</p>
            </footer>
        </div>
    );
};

export default TermsAndConditionsPage;
