import React, { useState, useEffect, useRef } from 'react';
import Spinner from 'react-bootstrap/Spinner';
import { TwitterShareButton, LinkedinShareButton, WhatsappShareButton } from 'react-share';
import axios from 'axios';
import {ReactTyped} from 'react-typed'; // Ensure this import matches what's available in your `react-typed` version.

const GenerateContent = ({ error, loading, generateUrlsResults, isMobile, showForm, setShowForm, isLoggedIn }) => {
    const [showModal, setShowModal] = useState(false);
    const [selectedImage, setSelectedImage] = useState('');
    const [shouldShowShareToEarn, setShouldShowShareToEarn] = useState(false);
    const [progress, setProgress] = useState(0);

    // Ref for the Typed.js text
    const typedRef = useRef(null);

    useEffect(() => {
        if (loading) {
            setProgress(0); // Reset progress to 0 when loading starts
            let start = Date.now();
            const interval = setInterval(() => {
                let elapsed = Date.now() - start;
                let percentage = Math.min((elapsed / 30000) * 100, 100);
                setProgress(percentage.toFixed(0));
            }, 400);

            return () => clearInterval(interval);
        }
    }, [loading]);


    // To enable share to earn:
    // un comment this, update cipy of the main button "2 time free" on home section,
    // be: set 0 credit on signup new user

    // useEffect(() => {
    //     const checkEligibility = async () => {
    //         try {
    //             const response = await axios.get(process.env.REACT_APP_BASE_SERVER_URL + `/api/v1/Share-to-earn/isEligible`, {
    //                 withCredentials: true
    //             });
    //             setShouldShowShareToEarn(response.data);
    //         } catch (error) {
    //             console.error('Error checking share-to-earn eligibility: AccountId:', isLoggedIn);
    //         }
    //     };
    //
    //     checkEligibility();
    // }, [isLoggedIn]);

    const fetchBase64Image = async (imageUrl) => {
        try {
            const response = await axios.post(
                process.env.REACT_APP_BASE_SERVER_URL + '/api/v1/image/image-to-base64',
                imageUrl,
                {
                    withCredentials: true,
                    headers: {
                        'Content-Type': 'text/plain',
                    },
                    params: { isLoggedIn }
                }
            );

            if (response.status === 200) {
                return response.data;
            } else {
                console.error('Unexpected response status:', response.status);
                return null;
            }
        } catch (error) {
            console.error('Error converting image to base64, accountId: ' + isLoggedIn, error.response?.data || error.message);
            return null;
        }
    };

    const handleDownload = async (imageUrl) => {
        const base64Image = await fetchBase64Image(imageUrl);
        if (base64Image) {
            const link = document.createElement('a');
            link.href = base64Image;
            link.setAttribute('download', 'AI-Tattoo.png');
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
        }
    };

    const handleShare = async () => {
        if (shouldShowShareToEarn) {
            try {
                await axios.post(process.env.REACT_APP_BASE_SERVER_URL + `/api/v1/Share-to-earn/request`, null, {
                    withCredentials: true
                });

                setShouldShowShareToEarn(false);
            } catch (error) {
                console.error('Error sending share-to-earn request');
            }
        }
    };

    const openModal = (imageUrl) => {
        setSelectedImage(imageUrl);
        setShowModal(true);
    };

    const closeModal = () => {
        setSelectedImage('');
        setShowModal(false);
    };

    const sanitizeInput = (input) => {
        return input.replace(/[^a-zA-Z0-9-_]/g, '');
    };

    const extractUserIdAndImageId = (imageUrl) => {
        const parts = imageUrl.split('/');
        const userId = sanitizeInput(parts[3]);
        const imageId = sanitizeInput(parts[4].split('_')[1].split('.')[0]);
        return { userId, imageId };
    };

    return (
        <>
            {isMobile && !showForm && !loading && (
                <button onClick={() => setShowForm(true)} className="generate-another-button">
                    Generate Another
                </button>
            )}
            <div className="generate-content">
                <div className="title-container">
                    {error && (
                        <div>
                            <h3>Our Server is busy due to users high traffic<br /> please try again in few minutes...</h3>
                        </div>
                    )}
                    {loading && (
                        <div className="loading-generation-content">
                            <div className="loading-text">
                                <div ref={typedRef}>
                                    <ReactTyped
                                        strings={[
                                            'Creating your unique Tattoo design...🎨',
                                            'Our advanced AI is crafting a one-of-a-kind Tattoo for you 🚀',
                                            'This may take up to 60 seconds, but it’s worth the wait!',
                                            'Hang tight, we’re almost done! 😊'
                                        ]}
                                        typeSpeed={65}
                                        loop
                                    />
                                </div>
                                <Spinner animation="border" role="status" className="spinner-border">
                                    <span className="visually-hidden">Loading...</span>
                                </Spinner>
                            </div>
                            <div className="progress-container">
                                <div className="progress-bar">
                                    <div className="progress" style={{width: `${progress}%`}}></div>
                                </div>
                                <p>{progress}%</p>
                            </div>
                        </div>
                    )}
                    {generateUrlsResults && !loading && !error && (
                        <div className="results">
                            <h3>Your Tattoo is ready🎉</h3>
                            {shouldShowShareToEarn && (
                                <p className="share-text">Share to earn 2 FREE credits (One-time reward per user)</p>
                            )}
                            <div className="image-grid">
                                {generateUrlsResults.map((imageUrl, index) => {
                                    const { userId, imageId } = extractUserIdAndImageId(imageUrl);
                                    const shareUrl = `${window.location.origin}/viewTattoo/${userId}/${imageId}`;
                                    return (
                                        <div key={index} className="result-item">
                                            <img
                                                src={imageUrl}
                                                alt="Generated Tattoo"
                                                onClick={() => openModal(imageUrl)}
                                            />

                                            <div className="share">
                                                <button onClick={() => handleDownload(imageUrl)} className="btn btn-primary">
                                                    <i className="bi bi-download"></i>
                                                </button>
                                                <TwitterShareButton
                                                    url={shareUrl}
                                                    title="Check out this Cool Tattoo I just created using TattooGPT.Art! 🥰"
                                                    onShareWindowClose={handleShare}
                                                >
                                                    <button className="btn btn-info">
                                                        <i className="bi bi-twitter"></i>
                                                    </button>
                                                </TwitterShareButton>

                                                <LinkedinShareButton
                                                    url={shareUrl}
                                                    title="I just designed an Amazing Tattoo with TattooGPT.Art. Take a look! 🥰"
                                                    onShareWindowClose={handleShare}
                                                >
                                                    <button className="btn btn-info">
                                                        <i className="bi bi-linkedin"></i>
                                                    </button>
                                                </LinkedinShareButton>

                                                <WhatsappShareButton
                                                    url={shareUrl}
                                                    title="Check out this Cool Tattoo I created with TattooGPT.Art! 🥰"
                                                    separator=" - "
                                                    onShareWindowClose={handleShare}
                                                >
                                                    <button className="btn btn-success">
                                                        <i className="bi bi-whatsapp"></i>
                                                    </button>
                                                </WhatsappShareButton>

                                            </div>
                                        </div>
                                    );
                                })}
                            </div>
                        </div>
                    )}
                </div>
                {showModal && (
                    <div className="modal" onClick={closeModal}>
                        <span className="modal-close" onClick={closeModal}>&times;</span>
                        <img className="modal-image" src={selectedImage} alt="Generated Tattoo" />
                    </div>
                )}
            </div>
        </>
    );
};

export default GenerateContent;
