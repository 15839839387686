import React, { useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { Button, Container, Row, Col, Card } from 'react-bootstrap';
import './ThankYouPage.css';

const ThankYouPage = () => {
    const location = useLocation();
    const navigate = useNavigate();
    let {details, productId, price, productName} = location.state || {};

    // Google Ads conversion tracking script, run unconditionally
    useEffect(() => {
        if (details) {
            window.gtag('event', 'conversion', {
                'send_to': 'AW-16737852360/JvdxCJeH-94ZEMi3na0-',
                'value': 1.0,
                'currency': 'ILS',
                'transaction_id': details.id || ''
            });
        }
    }, [details]);

    if (!details) {
        return <div>Error: No transaction details available.</div>;
    }

    const handleContinue = () => {
        navigate('/generate'); // Redirect to Generate page
    };

    // Determine if the transaction is one-time or subscription
    const isSubscription = details.subscriber;

    // Extract required details from the payment details based on the type of transaction
    const paymentId = details.id;
    const paymentStatus = details.status;
    const paymentCreateTime = details.create_time;

    let subscriberName, subscriberEmail, planName, startTime, nextBillingTime, lastPaymentAmount, lastPaymentCurrency;

    if (isSubscription) {
        subscriberName = details.subscriber.name;
        subscriberEmail = details.subscriber.email_address;
        startTime = new Date(details.start_time).toLocaleString();
        nextBillingTime = new Date(details.billing_info.next_billing_time).toLocaleString();
        lastPaymentAmount = details.billing_info.last_payment.amount.value;
        lastPaymentCurrency = details.billing_info.last_payment.amount.currency_code;
    } else {
        // Handle one-time payment details
        lastPaymentAmount = price; // Use the price passed in location.state
        lastPaymentCurrency = details.purchase_units[0].amount.currency_code;
    }

    return (
        <Container className="text-center">
            <Row className="justify-content-center">
                <Col md={6}>
                    <Card className="shadow-lg p-4 mb-5 bg-white rounded">
                        <Card.Body>
                            <img className="Thankyou-green-check-img"
                                 src="https://ai-tattoo-website-images.s3.amazonaws.com/green.png"
                                 alt="Thank You"/>

                            <h3 className="main-title">Thank You for Your Purchase!</h3>
                            <br/><br/>
                            <div className="thank-you-container">
                                <h5 className="summary-title">Summary Details:</h5>
                                <ul className="list-unstyled">
                                    <li><strong>Order ID:</strong> {paymentId}</li>
                                    <li><strong>Plan:</strong> TattooGPT {productName} Plan</li>
                                    <li><strong>Status:</strong> {paymentStatus}</li>
                                    <li><strong>Total Price:</strong> {lastPaymentAmount} {lastPaymentCurrency}</li>
                                    {isSubscription && (
                                        <>
                                            <li><strong>Start Time:</strong> {startTime}</li>
                                            <li><strong>Next Renewal:</strong> {nextBillingTime}</li>
                                            <li><strong>Subscriber
                                                Name:</strong> {`${subscriberName.given_name} ${subscriberName.surname}`}
                                            </li>
                                            <li><strong>Confirmation Email will be sent to:</strong> {subscriberEmail}
                                            </li>
                                        </>
                                    )}
                                </ul>
                            </div>
                            <Button onClick={handleContinue}>Continue</Button>
                        </Card.Body>
                    </Card>
                </Col>
            </Row>
        </Container>
    );
};

export default ThankYouPage;
