import React from 'react';
import Spinner from 'react-bootstrap/Spinner';
import './LoadingSpinner.css';

const LoadingSpinner = ({message}) => (
    <div className="spinner-container">
        <Spinner animation="border" role="status" variant="light">
            <span className="visually-hidden">Loading...</span>
        </Spinner>
        <h3 className="spinner-message">{message}</h3>
    </div>
);

export default LoadingSpinner;
