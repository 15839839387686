import React, {useState, useEffect} from 'react';
import axios from 'axios';
import ComingSoonPage from "../CommingSoon/ComingSoonPage";
import Spinner from 'react-bootstrap/Spinner'; // Importing Spinner from Bootstrap

// Block all users from the web - allow only me
// By ip,
// I created new component that send get request to service that get user ip
// And check if ip is my ip
// If it is: show website
// If not: loads “coming Soon” page

const ALLOWED_IPS = ["85.64.129.125","2.54.59.253"];

const AllowIPComponent = ({children}) => {
    const [userIP, setUserIP] = useState(null);
    const [isAllowed, setIsAllowed] = useState(false);
    const [loadingSpinner, setLoadingSpinner] = useState(true); // Track loading state

    useEffect(() => {
        const shouldVerifyIp = false; //process.env.REACT_APP_IP_VALIDATION == 1;
        
        if (shouldVerifyIp) {
            // Only perform IP check if in production
            axios.get('https://api.ipify.org?format=json')
                .then(response => {
                    const ip = response.data.ip;
                    setUserIP(ip);

                    // Check if the user's IP matches any of the allowed IPs
                    if (ALLOWED_IPS.includes(ip)) {
                        setIsAllowed(true);
                    }
                })
                .catch(err => {
                    console.error("eligibility check fail");
                    setIsAllowed(false); // Set to false on error to prevent access
                })
                .finally(() => {
                    setLoadingSpinner(false); // Stop loading once the IP check completes
                });
        } else {
            // If not in production, allow access
            setIsAllowed(true);
            setLoadingSpinner(false);
        }
    }, []);

    // While loading, show a spinner
    if (loadingSpinner) {
        return (
            <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh'}}>
                <Spinner animation="border" role="status" className="spinner-border">
                    <span className="visually-hidden">Loading...</span>
                </Spinner>
            </div>
        );
    }

    // If the IP is not allowed, render the ComingSoon page
    if (!isAllowed) {
        return <ComingSoonPage/>;
    }

    // Render the children (rest of the app) if the IP is allowed
    return <>{children}</>;
}

export default AllowIPComponent;