import React from 'react';
import {Link} from 'react-router-dom';
import './Footer.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap-icons/font/bootstrap-icons.css';

// Footer
const Footer = () => {
    return (
        <footer className="footer">
            <div className="footer-container">
                <div className="footer-column">
                    <div className="footer-logo-container">
                        <img
                            src="https://ai-tattoo-website-images.s3.amazonaws.com/icon_gold.webp"
                            alt="AI Tattoo Icon"
                            style={{width: '2.5rem', height: '2.5rem', marginLeft: '0.1rem', marginRight: '0.7rem'}}
                        />
                        TattooGPT.Art
                    </div>
                    <p>Enter your ideas and watch as they turn into stunning Tattoos.</p>
                    <p>Share you results with your friends and family.</p>
                    <div className="social-icons">
                        {/*<a href="https://twitter.com/yourprofile" target="_blank" rel="noopener noreferrer">*/}
                        {/*    <i className="bi bi-twitter-x"></i>*/}
                        {/*</a>*/}
                        {/*<a href="https://linkedin.com/in/yourprofile" target="_blank" rel="noopener noreferrer">*/}
                        {/*    <i className="bi bi-linkedin"></i>*/}
                        {/*</a>*/}
                        <a href="https://youtube.com/@TattooGPT" target="_blank" rel="noopener noreferrer">
                            <i className="bi bi-youtube"></i>
                        </a>
                        <a href="https://tiktok.com/@tattoogpt.art" target="_blank" rel="noopener noreferrer">
                            <i className="bi bi-tiktok"></i>
                        </a>
                        <a href="https://www.instagram.com/tattoogpt.art/" target="_blank" rel="noopener noreferrer">
                            <i className="bi bi-instagram"></i>
                        </a>
                    </div>

                </div>
                <div className="footer-column">
                    <h3>Company:</h3>
                    <Link to={"/about-us"}>
                        <p>About Us</p>
                    </Link>
                    <Link to={"/Privacy-Policy"}>
                        <p>Privacy Policy</p>
                    </Link>
                    <Link to={"/Terms-And-Conditions"}>
                        <p>Terms And Conditions</p>
                    </Link>
                    <Link to={"/help-center"}>
                        <p>Contact Us</p>
                    </Link>
                    <p>Press</p>
                </div>
                <div className="footer-column">
                    <h3>Support:</h3>
                    <Link to={"/How-To-Create-Tattoo-Design-Using-Ai"}>
                        <p>How to create Tattoo Design with Ai</p>
                    </Link>
                    <Link to={"/The-Best-Tattoo-Generator"}>
                        <p>The Best AI Tattoo Generator</p>
                    </Link>
                    <Link to={"/help-center"}>
                        <p>Help Center</p>
                    </Link>
                    <Link to={"/Feedback"}>
                        <p>Feedback</p>
                    </Link>
                </div>
                <div className="footer-column">
                    <h3>Our Products:</h3>
                    <p>Whats new</p>
                    <Link to={"/Pricing"}>
                        <p>Pricing</p>
                    </Link>
                    <Link to={"/ApplyCoupon"}>
                        <p>Apply Coupon</p>
                    </Link>
                </div>
            </div>
            <hr className="hr"/>
            <div className="footer-bottom">
                <p>&copy; 2024 TattooGPT.Art All rights reserved.</p>
            </div>
        </footer>
    );
}


export default Footer;
