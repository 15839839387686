import React, { useState } from 'react';
import DOMPurify from 'dompurify'; // Import DOMPurify for sanitization
import './GenerateForm.css';
import {useLogin} from "../UserAutentication/LoginProvider";

const GenerateForm = ({
                          handleSubmit,
                          prompt,
                          setPrompt,
                          bodypart,
                          setBodypart,
                          size,
                          setSize,
                          colorStyle,
                          setColorStyle,
                          gender,
                          setGender,
                          resultsAmount,
                          setResultsAmount,
                          loading,
                          hasOneCredit
                      }) => {
    const {hasActiveSubscription} = useLogin();
    // Local state for validation error
    const [error, setError] = useState('');

    // Frontend input validation function for the prompt
    const handlePromptChange = (e) => {
        const value = e.target.value;

        // Allow letters (including non-English), numbers, punctuation, and whitespace, and limit length
        if (/^[\p{L}\p{N}\s,.]*$/u.test(value) && value.length <= 400) {
            setPrompt(DOMPurify.sanitize(value)); // Sanitize the input
            setError(''); // Clear any previous errors
        } else {
            setError('(only characters - max 400)');
        }
    };

    return (
        <div className="generate-form">
            <h3>Tattoo Generator</h3>
            <form onSubmit={handleSubmit}>
                <div className="form-row">
                    <label>Idea:</label>
                    <textarea
                        value={prompt}
                        onChange={handlePromptChange} // Apply input validation
                        placeholder="Describe your tattoo idea, use as many details as you can for better results"
                        rows="2"
                        className="form-control"
                    />
                    {error && <p className="error-text">{error}</p>} {/* Display validation error */}
                </div>

                <div className="form-row">
                    <label>Color:</label>
                    <select
                        value={colorStyle}
                        onChange={(e) => setColorStyle(DOMPurify.sanitize(e.target.value))} // Sanitize value
                        className="form-control"
                    >
                        <option value="deep dark black and subtle dark gray">Black and gray</option>
                        <option value="dark green almost black">Dark Green</option>
                        <option value="warm colors">Warm Colors</option>
                        <option value="cold colors">Cold Colors</option>
                    </select>
                </div>

                <div className="form-row">
                    <label>Body Part:</label>
                    <select
                        value={bodypart}
                        onChange={(e) => setBodypart(DOMPurify.sanitize(e.target.value))} // Sanitize value
                        className="form-control"
                    >
                        <option value="forearm">Forearm</option>
                        <option value="upper arm">Upper Arm</option>
                        <option value="upper back">Upper Back</option>
                        <option value="lower back">Lower Back</option>
                        <option value="upper chest">Upper Chest</option>
                        <option value="wrist">Wrist</option>
                        <option value="thigh">Thigh</option>
                        <option value="ankle">Ankle</option>
                        <option value="neck">Neck</option>
                        <option value="face">Face</option>
                        <option value="sketch">General Sketch</option>
                    </select>
                </div>

                <div className="form-row">
                    <label>Size:</label>
                    <select
                        value={size}
                        onChange={(e) => setSize(DOMPurify.sanitize(e.target.value))} // Sanitize value
                        className="form-control"
                    >
                        <option value="Small">Small & Minimalistic</option>
                        <option value="Medium">Medium</option>
                        <option value="Large">Large</option>
                    </select>
                </div>

                <div className="form-row">
                    <label>Gender:</label>
                    <select
                        value={gender}
                        onChange={(e) => setGender(DOMPurify.sanitize(e.target.value))} // Sanitize value
                        className="form-control"
                    >
                        <option value="">Any</option>
                        <option value="male body (man tattoo)">Male</option>
                        <option value="female">Female</option>
                        <option value="prefer not to answer">Prefer Not to Answer</option>
                    </select>
                </div>

                <div className="form-row">
                    <label>Results: (2+ for subscribers only)</label>
                    <select
                        value={resultsAmount}
                        onChange={(e) => setResultsAmount(DOMPurify.sanitize(e.target.value))} // Sanitize value
                        className="form-control"
                    >
                        <option value="1">1 Result</option>

                        {!hasOneCredit && (<option value="2">2 Results</option>)}
                        {/*<option value="3">3 Results</option> or for subscriptions ca use  hasActiveSubscription */}
                    </select>
                </div>

                <button type="submit" className="generate-button btn btn-primary" disabled={error || loading}>
                    {loading ? 'Generating...' : 'Generate'}
                </button>
            </form>
        </div>
    );
};

export default GenerateForm;



