import React, {useEffect, useState} from 'react';
import {useLogin} from "../UserAutentication/LoginProvider";
import axios from "axios";
import './MyGallery.css';
import {useNavigate} from "react-router-dom";
import Skeleton from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';
import LoginPage from "../UserAutentication/LogInPage";
import {Helmet} from "react-helmet";

const MyGallery = () => {
    const {isLoggedIn} = useLogin();
    const [userImagesUrls, setUserImagesUrls] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const [clickedImage, setClickedImage] = useState(null);
    const [visibleImages, setVisibleImages] = useState(22); // Initially load 15 images
    const navigate = useNavigate();

    useEffect(() => {
        if (isLoggedIn) {
            fetchUserImagesUrls();
        }
    }, [isLoggedIn]);

    const fetchUserImagesUrls = async () => {
        setIsLoading(true); // Ensure loading state is set before making the request

        try {
            const response = await axios.post(process.env.REACT_APP_BASE_SERVER_URL + '/api/v1/MyGallery/GetImagesUrls', {}, {
                withCredentials: true
            });

            if (response.status === 200) {
                setUserImagesUrls(response.data);
            } else if (response.status === 204) {
                console.log('No images found for the user.');
                setUserImagesUrls([]); // Clear or set empty state if no content
            } else {
                console.error('Unexpected response status:', response.status);
            }
        } catch (error) {
            console.error('Error fetching user images for gallery, accountId: ' + isLoggedIn, error.response?.data || error.message);
            // Optionally, provide user feedback here
        } finally {
            setIsLoading(false); // Ensure loading state is cleared
        }
    };

    const handleGenerate = () => {
        navigate('/generate');
    };

    const handleImageClick = (url) => {
        setClickedImage(url);
    };

    const handleCloseModal = () => {
        setClickedImage(null);
    };

    const loadMoreImages = () => {
        setVisibleImages(prevVisibleImages => prevVisibleImages + 22);
    };

    if (!isLoggedIn) {
        return <LoginPage/>;
    }

    return (
        <>
            <Helmet>
                <title>My Gallery | TattooGPT</title>
                <meta name="description" content="Explore your collection of stunning AI-generated tattoo designs. Save and showcase your favorite tattoos created with TattooGPT." />
                <meta name="keywords" content="Tattoo Gallery, AI Tattoos, Custom Tattoo Designs, My Gallery, Tattoo Collection" />
                <link rel="canonical" href="https://www.tattoogpt.art/MyGallery" />
            </Helmet>

        <div className="gallery-container">
            <div className="gallery-title">
                <h2>My Gallery</h2>
            </div>
            {isLoading ? (
                <div className="loading-container">
                    {[...Array(22)].map((_, index) => (
                        <Skeleton key={index} height={200}/>
                    ))}
                </div>
            ) : userImagesUrls.length > 0 ? (
                <div>
                    <div className="gallery-grid">
                        {userImagesUrls.slice(0, visibleImages).map((url, index) => (
                            <div
                                key={index}
                                className="image-container"
                                onClick={() => handleImageClick(url)}
                            >
                                <img
                                    src={url}
                                    alt={`User Image ${index + 1}`}
                                    className="gallery-image"
                                />
                            </div>
                        ))}
                    </div>
                    {visibleImages < userImagesUrls.length && (
                        <button className="load-more-button" onClick={loadMoreImages}>
                            Load More
                        </button>
                    )}
                </div>
            ) : (
                <div className="no-images">
                    <p>You haven't generated any tattoos yet.</p>
                    <button className="btn btn-primary" onClick={handleGenerate}>Generate Tattoo</button>
                </div>
            )}
            {clickedImage && (
                <div className="modal" onClick={handleCloseModal}>
                    <img src={clickedImage} alt="Clicked" className="modal-image"/>
                </div>
            )}
        </div>
        </>
    );
}

export default MyGallery;
