import React, { useState, useEffect } from 'react';
import Section from './Section';
import './Home.css';
import { Link } from 'react-router-dom';
import { useLogin } from "../UserAutentication/LoginProvider";
import DiscountBanner from "./DiscountBanner/DiscountBanner";
import PaywallPage from "../PaywallPage/PaywallPage";
import CreationsPopup from "./CreationsPopup/CreationsPopup";
import CookieConsentBanner from "../CookieConsentBanner/CookieConsentBanner";
import OneTimePaywallPage from "../PaywallPage/OneTimePaywallPage";
import ShortFAQ from "./ShortFAQcomponent/ShortFAQ";

const Home = () => {
    const { hasActiveSubscription } = useLogin();
    const [isPopupOpen, setIsPopupOpen] = useState(false);
    const { isLoggedIn } = useLogin();

    // Primary image URLs for the homepage gallery to showcase AI-generated tattoos.
    const MainHomePageImageUrls = [
        'https://ai-tattoo-website-images.s3.us-east-1.amazonaws.com/homegallery9.png',
        'https://ai-tattoo-website-images.s3.us-east-1.amazonaws.com/homegallery8.png',
        'https://ai-tattoo-website-images.s3.us-east-1.amazonaws.com/homegallery3.png',
        'https://ai-tattoo-website-images.s3.us-east-1.amazonaws.com/home_first_gallery4.png',
        'https://ai-tattoo-website-images.s3.us-east-1.amazonaws.com/home_first_gallery5.png',
        'https://ai-tattoo-website-images.s3.us-east-1.amazonaws.com/home_first_gallery6.png',
        'https://ai-tattoo-website-images.s3.us-east-1.amazonaws.com/home_first_gallery7.png',
        'https://ai-tattoo-website-images.s3.us-east-1.amazonaws.com/home_first_gallery9.png',
        'https://ai-tattoo-website-images.s3.us-east-1.amazonaws.com/home_first_gallery11.png',
        'https://ai-tattoo-website-images.s3.us-east-1.amazonaws.com/home_first_gallery12.png',
        'https://ai-tattoo-website-images.s3.us-east-1.amazonaws.com/home_first_gallery13.png',
    ];

    const blondHairLadyImageUrls = [
        'https://ai-tattoo-s3-bucket.s3.amazonaws.com/576369-TKL/AI-Tattoo-Generated-image_14942231726650132791.jpg',
        'https://ai-tattoo-s3-bucket.s3.amazonaws.com/576369-TKL/AI-Tattoo-Generated-image_9185345822888484342.jpg',
        'https://ai-tattoo-s3-bucket.s3.amazonaws.com/576369-TKL/AI-Tattoo-Generated-image_17640812993595825150.jpg',
        'https://ai-tattoo-s3-bucket.s3.amazonaws.com/888335-WIN/AI-Tattoo-Generated-image_5640019573988822343.jpg',
        'https://ai-tattoo-s3-bucket.s3.amazonaws.com/888335-WIN/AI-Tattoo-Generated-image_1198610166022035163.jpg',
    ];

    const pinkHairLadyImageUrls = [
        'https://ai-tattoo-s3-bucket.s3.amazonaws.com/576369-TKL/AI-Tattoo-Generated-image_17956667163193335444.jpg',
        'https://ai-tattoo-s3-bucket.s3.amazonaws.com/576369-TKL/AI-Tattoo-Generated-image_292669848671852551.jpg',
        'https://ai-tattoo-s3-bucket.s3.amazonaws.com/888335-WIN/AI-Tattoo-Generated-image_12214556829072824363.jpg',
        'https://ai-tattoo-s3-bucket.s3.amazonaws.com/888335-WIN/AI-Tattoo-Generated-image_2988531963538105001.jpg',
    ];

    const howItWorkImageUrls = [
        'https://ai-tattoo-website-images.s3.amazonaws.com/Screenshot+2024-10-19+at+11.38.27.png',
        'https://ai-tattoo-website-images.s3.amazonaws.com/Screenshot+2024-10-19+at+11.39.55.png',
        'https://ai-tattoo-website-images.s3.amazonaws.com/Screenshot+2024-10-19+at+11.40.46.png',
        'https://ai-tattoo-website-images.s3.amazonaws.com/Screenshot+2024-10-19+at+11.43.40.png',
        'https://ai-tattoo-website-images.s3.amazonaws.com/Screenshot+2024-10-19+at+11.40.46.png',
        'https://ai-tattoo-website-images.s3.amazonaws.com/Screenshot+2024-10-19+at+11.45.03.png',
        'https://ai-tattoo-website-images.s3.amazonaws.com/Screenshot+2024-10-19+at+11.46.36.png',
    ];


    const [currentImageIndex, setCurrentImageIndex] = useState(0);

    // Rotate images on a timer for the main gallery.
    useEffect(() => {
        const intervalId = setInterval(() => {
            setCurrentImageIndex(prevIndex => (prevIndex + 1) % MainHomePageImageUrls.length);
        }, 1500);

        return () => clearInterval(intervalId);
    }, []);

    // Open popup on pressing 'A' for showcasing user tattoo creations.
    useEffect(() => {
        const handleKeyPress = (event) => {
            if (event.key === 'a' || event.key === 'A') {
                setIsPopupOpen(true);
            }
        };

        window.addEventListener('keydown', handleKeyPress);

        return () => {
            window.removeEventListener('keydown', handleKeyPress);
        };
    }, []);

    const handleClosePopup = () => {
        setIsPopupOpen(false);
    };

    const selectedMainHomePageImageUrl = MainHomePageImageUrls[currentImageIndex];
    const selectedBlondImageUrl = blondHairLadyImageUrls[Math.floor(Math.random() * blondHairLadyImageUrls.length)];
    const selectedPinkImageUrl = pinkHairLadyImageUrls[Math.floor(Math.random() * pinkHairLadyImageUrls.length)];
    const selectedHowItWorkImageUrls = howItWorkImageUrls[Math.floor(Math.random() * howItWorkImageUrls.length)];

    return (
        <>
            {/*show cookie banner if he didnt approve*/}
            <CookieConsentBanner/>

            {/*show discount banner for user without an active subscription*/}
            {!hasActiveSubscription && <DiscountBanner/>}

        <div className="home-container">

            <CreationsPopup isOpen={isPopupOpen} onClose={handleClosePopup}/>

            {/*first section - home*/}
            <div className="first-section" style={{backgroundColor: "#05020e"}}>
                <div className="section-children">
                    <div className="section-content">
                        <h1 className="main-section-title">AI Tattoo Generator 🔥</h1>
                        <h5>Create Custom Tattoo Designs from Text with our Tattoo Design Generator</h5>
                        <ul className="main-section-benefits">
                            <li>🌟 Unique AI Tattoo Designs</li>
                            <li>💡 Explore Tattoo Styles & Placements</li>
                            <li>⏳ Save Time—No More Endless Searching!</li>
                            <li>🤑 Affordable Designs—No Artist Needed!</li>
                            <Link to={'/Generate'} style={{textDecoration: 'none'}}>
                                {!isLoggedIn ? (
                                    <>
                                        <button className="get-started-button">
                                            Try It FREE! 🚀
                                        </button>
                                        <span className="new-user-note" style={{
                                            fontSize: '0.9rem',
                                            color: '#f8f5f5',
                                            marginTop: '2px',
                                            marginBottom: '-17px',
                                            display: 'block',
                                        }}>
                                                *Free Try for new users - No Credit card
                                            </span>
                                    </>
                                ) : (
                                    <button className="get-started-button">
                                        Create Tattoo With AI
                                    </button>
                                )}
                            </Link>
                        </ul>
                    </div>
                </div>
                <div className="section-image-container">
                    <img src={selectedMainHomePageImageUrl} alt="Tattoo design" className="section-image"/>

                    {/* Keyboard shortcut for popup */}
                    <div className="press-a-container">
                            <span className="press-a-text">
                                Press <span className="square-a">A</span> to view Cool Tattoo Creations by TattooGPT.Art
                            </span>
                        </div>
                    </div>
                </div>

                {/* TattooGPT Demo Section with video */}
                <div className="tattoogpt-demo-section" style={{ backgroundColor: "#05020e", padding: "20px 20px" }}>
                    <div
                        className="video-container"
                        style={{ display: "flex", justifyContent: "center", marginTop: "60px" }}
                    >
                        <iframe
                            width="290"
                            height="500"
                            src="https://www.youtube.com/embed/DtBsbkDKBnc?rel=0&modestbranding=1"
                            title="AI Tattoo Generator"
                            allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                        />
                    </div>
                </div>

                <Section backgroundColor="#05020e" imageUrl={selectedPinkImageUrl}>
                    <div className="articles-section-content">
                        <h2 className="articles-title">Featured Articles</h2>

                    <div className="article-card"
                         onClick={() => window.open('https://www.openpr.com/news/3739057/introducing-tattoogpt-art-the-leading-ai-tattoo-design', '_blank', 'noopener noreferrer')}>
                            <img
                                src="https://www.openpr.com/fx/openpr-logo-slogan.svg"
                                alt="OpenPR Logo"
                                className="article-logo"
                            />
                            <h5 className="article-title">"TattooGPT.Art is Probably the Best AI Tattoo Generator Out There"</h5>
                            <a
                                href="https://www.openpr.com/news/3739057/introducing-tattoogpt-art-the-leading-ai-tattoo-design"
                                target="_blank"
                                rel="noopener noreferrer"
                                className="read-more-link"
                            >Read More</a>
                        </div>

                        <div className="article-card" onClick={() => window.open('https://fwnbc.marketminute.com/article/abnewswire-2024-11-15-introducing-tattoogptart-the-leading-ai-tattoo-design-generator-of-2025', '_blank', 'noopener noreferrer')}>
                            <img
                                src="https://www.fortwaynesnbc.com/pf/resources/images/mastheads/logos/wpta-nbc.svg?d=280"
                                alt="LiveWire Reporter Logo"
                                className="article-logo"
                            />
                            <h5 className="article-title">"The Leading AI Tattoo Design Generator of 2025 - TattooGPT.Art "</h5>
                            <a
                                href="https://fwnbc.marketminute.com/article/abnewswire-2024-11-15-introducing-tattoogptart-the-leading-ai-tattoo-design-generator-of-2025"
                                target="_blank"
                                rel="noopener noreferrer"
                                className="read-more-link"
                            >Read More</a>
                        </div>
                    </div>
                </Section>

            </div>
            {/* Section 3: Black background */}
            {!hasActiveSubscription &&
                <div className="first-section paywall-section" style={{backgroundColor: "black"}}>
                    <div className="section-children">
                        <OneTimePaywallPage/>
                        {/*for subscriptions payment paywall use <PaywallPage/>*/}
                    </div>
                </div>}


            <Section backgroundColor="#05020e" imageUrl={selectedBlondImageUrl}>
                <div className="why-choose-us-section-content">
                    <h2>Why Choose Us</h2>
                    <p><i className="icon bi bi-check-circle"></i>Top AI Tattoo creator!</p>
                    <p><i className="icon bi bi-check-circle"></i>Design Realistic Tattoos in Seconds!</p>
                    <p><i className="icon bi bi-check-circle"></i>No More Scrolling on Pinterest!</p>
                    <p><i className="icon bi bi-check-circle"></i>Tattoo Ideas to Reality fast.!</p>
                    <p><i className="icon bi bi-check-circle"></i>Save Time & Money - no artist!</p>
                    <p><i className="icon bi bi-check-circle"></i>Enjoy Advanced Features and updates!</p>
                </div>
            </Section>

            <ShortFAQ />


            <div className="seo-links">
                <h2>Learn More About Tattoo GPT Art</h2>
                <ul>
                    <li><a href="https://www.tattoogpt.art/about-us" title="About Us - Tattoo GPT Art">About Us</a></li>
                    <li><a href="https://www.tattoogpt.art/help-center" title="Help Center - Tattoo GPT Art">Help Center</a></li>
                    <li><a href="https://www.tattoogpt.art/How-To-Create-Tattoo-Design-Using-Ai" title="How To Create Tattoo Design Using AI - Tattoo GPT Art">How To Create Tattoo Design Using AI</a></li>
                    <li><a href="https://www.tattoogpt.art/The-Best-Tattoo-Generator" title="The Best Tattoo Generator - Tattoo GPT Art">The Best Tattoo Generator</a></li>
                    <li><a href="https://www.tattoogpt.art/Feedback" title="Feedback - Tattoo GPT Art">Feedback</a></li>
                </ul>
            </div>
        </>
    );
}

export default Home;
