import React, {useEffect, useState} from 'react';
import './DiscountBanner.css';

const DiscountBanner = () => {
    const [paywallSection, setPaywallSection] = useState(null);

    useEffect(() => {
        // Find the paywall section once the component mounts
        const section = document.getElementsByClassName('paywall-section')[0];
        setPaywallSection(section);
    }, []);

    const scrollToPaywall = () => {
        if (paywallSection) {
            // Use scrollIntoView to bring the section into view
            paywallSection.scrollIntoView({behavior: 'smooth', block: 'center'});
        } else {
            console.error('Paywall section not found!');
        }
    };

    return (
        <div className="banner-container" onClick={scrollToPaywall}>
            <div className="banner-text">
                🚨Exclusive Deals! Get It Now 🔥
            </div>
        </div>
    );
};

export default DiscountBanner;
