import React from 'react';
import './AboutUsPage.css';
import { Helmet } from "react-helmet";

const AboutUsPage = () => {
    return (
        <>
            <Helmet>
                <title>About Us | TattooGPT</title>
                <meta name="description" content="Learn more about TattooGPT, our story, and how we create stunning AI-generated tattoo designs." />
                <meta name="keywords" content="Tattoo, AI, Tattoo Designs, About Us" />
                <link rel="canonical" href="https://www.tattoogpt.art/about-us" />
                <script type="application/ld+json">
                    {JSON.stringify({
                        "@context": "https://schema.org",
                        "@type": "AboutPage",
                        "name": "About Us - TattooGPT",
                        "description": "Learn more about TattooGPT, our story, and how we create stunning AI-generated tattoo designs.",
                        "mainEntityOfPage": {
                            "@type": "WebPage",
                            "@id": "https://www.tattoogpt.art/about-us"
                        },
                        "creator": {
                            "@type": "Person",
                            "name": "TattooGPT.Art",
                            "jobTitle": "Founder & Developer",
                            "affiliation": "TattooGPT.Art"
                        }
                    })}
                </script>
            </Helmet>
            <div className="aboutUs">
                <h1>About Us</h1>

                <div className="aboutUs-section">
                    <div className="aboutUs-text-content">
                        <h2>Our Story</h2>
                        <p>I always wanted a unique tattoo but didn't know how to draw. After hours on Instagram and Pinterest searching for the perfect design, I created TattooGPT.Art to help people like me find and create high-quality, unique tattoos easily.</p>

                        <h2>Background and Inspiration</h2>
                        <p>From a young age, I had a passion for technology. I built my first website at 12, with support from my mother, a computer science teacher. Later, during college, I created a website called cs18.co.il to share computer science knowledge for free.</p>

                        <h2>Professional Journey</h2>
                        <p>I balance my developer career with a passion for continuous learning. Over time, I've expanded my expertise across backend and frontend development, infrastructure, security, and more. Each project helped me master these areas.</p>

                        <h2>Founding TattooGPT.Art</h2>
                        <p>In May 2024, I started TattooGPT.Art as a one-person company to solve a personal problem many others face: creating a new tattoo without knowing how to draw. Many existing apps were either scams, low quality, or overpriced. I wanted to offer a reliable, affordable AI-powered platform for custom tattoo designs.</p>

                        <h2>Thank You</h2>
                        <p>Your support, feedback, and purchases keep this project alive. I'm thrilled to have you join me on this journey!</p>

                        <p>TattooGPT.Art creator</p>
                    </div>
                    <div className="aboutUs-image-content">
                        <img src="https://ai-tattoo-website-images.s3.amazonaws.com/home+page+blond+lady2.jpg"
                             alt="TattooGPT creator story image" className="about-us-image" />
                    </div>
                </div>
            </div>
        </>
    );
};

export default AboutUsPage;
