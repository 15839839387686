import React, {useState} from 'react';
import './WhyTattooGPTIsTheBest.css';
import {Helmet} from "react-helmet"; // Import the CSS file

const WhyTattooGPTIsTheBest = () => {
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [modalImageSrc, setModalImageSrc] = useState('');

    const openModal = (src) => {
        setModalImageSrc(src);
        setIsModalOpen(true);
    };

    const closeModal = () => {
        setIsModalOpen(false);
        setModalImageSrc('');
    };

    return (
        <>
            <Helmet>
                <title>Why TattooGPT Is The Best Tattoo Generator | TattooGPT</title>
                <meta name="description"
                      content="Discover why TattooGPT stands out as the best AI tattoo generator. Explore our unique features, user-friendly interface, and stunning designs."/>
                <meta name="keywords"
                      content="TattooGPT, Best Tattoo Generator, AI Tattoo, Custom Tattoos, Unique Tattoo Designs"/>
                <link rel="canonical" href="https://www.tattoogpt.art/the-best-tattoo-generator/"/>
            </Helmet>
            <div className="tattoo-ai-comparison">
                <header className="tattoo-ai-comparison__header">
                    <h1>🚀 Discover the Best AI Tattoo Generator!</h1>
                    <p>At TattooGPT.art, we combine cutting-edge AI technology with creativity to bring your tattoo
                        ideas to
                        life effortlessly! 💡</p>
                    <img
                        src="https://ai-tattoo-website-images.s3.amazonaws.com/Screenshot+2024-10-19+at+11.46.36.png"
                        alt="Realistic Tattoo Design Example"
                        className="tattoo-ai-comparison__image"
                        onClick={() => openModal("https://ai-tattoo-website-images.s3.amazonaws.com/Screenshot+2024-10-19+at+11.46.36.png")}
                    />
                    <a href="/" className="cta-button">🚀 Get Your Ai Tattoo Design Now!</a>
                </header>

                <section className="tattoo-ai-comparison__section">
                    <h2>Why TattooGPT.art? 🏆</h2>
                    <ul className="tattoo-ai-comparison__features">
                        <li>🎨 <strong>Hyper-Realistic Designs:</strong> Stunning tattoo designs, in black-and-white or
                            full
                            color.
                        </li>
                        <li>💪 <strong>Accurate Body Positioning:</strong> Preview tattoos on specific body parts with
                            gender-based accuracy.
                        </li>
                        <li>⚡ <strong>Super Fast:</strong> Generate up to 2 tattoos in under 30 seconds.</li>
                        <li>💼 <strong>Flexible Plans:</strong> Tailored to personal or business needs.</li>
                        <li>📞 <strong>Support:</strong> Fast, friendly, and always available for help.</li>
                        <li>🚀 <strong>New Features:</strong> Stay tuned for exciting updates!</li>
                    </ul>
                </section>

                <section className="tattoo-ai-comparison__section free-trial">
                    <h2>Try it now! 🎉</h2>
                    <p>Sign up and bring your tattoo ideas to life with TattooGPT! </p>
                    <a href="/" className="cta-button">🚀 Get Your Ai Tattoo Design Now!</a>
                    <img
                        src="https://ai-tattoo-website-images.s3.us-east-1.amazonaws.com/newhomeBackground01_small_desktop.jpg"
                        alt="Tattoo Design Process Demo"
                        className="tattoo-ai-comparison__image"
                        onClick={() => openModal("https://ai-tattoo-website-images.s3.us-east-1.amazonaws.com/newhomeBackground01_small_desktop.jpg")}
                    />
                </section>

                <section className="tattoo-ai-comparison__section social">
                    <p>🎥 Watch our demo videos optimized for mobile playback!</p>
                    <div className="video-container" style={{display: "flex", justifyContent: "center"}}>
                        <iframe
                            width="310"
                            height="560"
                            src="https://www.youtube.com/embed/02AryWm2iDY?rel=0"
                            title="TattooGPT.Art - AI Tattoo Generator, Create stunning Tattoos using AI -> Transform Your Ideas into Tattoos"
                            frameBorder="0"
                            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                            allowFullScreen
                            style={{borderRadius: "15px", maxWidth: "300"}}
                        ></iframe>
                    </div>
                </section>

                {isModalOpen && (
                    <div className="tattoo-ai-comparison__modal" onClick={closeModal}>
                        <span className="tattoo-ai-comparison__close" onClick={closeModal}>&times;</span>
                        <img className="tattoo-ai-comparison__modal-content" src={modalImageSrc} alt="TattooGPT Image"/>
                    </div>
                )}
            </div>
        </>
    );
};

export default WhyTattooGPTIsTheBest;
