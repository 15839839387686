import React, {createContext, useState, useContext} from 'react';

// Create a Context for the login state
const LoginContext = createContext();

// Create a custom hook to use the LoginContext
export const useLogin = () => {
    return useContext(LoginContext);
};



// Create a provider component
export const LoginProvider = ({children}) => {
    const [isLoggedIn, setIsLoggedIn] = useState(false);
    const [hasActiveSubscription, setHasActiveSubscription] = useState(false); // New state for subscription

    const value = {
        isLoggedIn,
        setIsLoggedIn,
        hasActiveSubscription,
        setHasActiveSubscription,
    };

    return (
        <LoginContext.Provider value={value}>
            {children}
        </LoginContext.Provider>
    );
};