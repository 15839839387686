import React, { useState } from 'react';
import './HelpCenterPage.css';
import { Helmet } from "react-helmet";

const faqData = [
    {
        question: "How do I use TattooGPT.Art?",
        answer: "Using TattooGPT.Art is simple! Just sign up, select your desired tattoo style, and use our AI-powered generator to create unique designs."
    },
    {
        question: "How do I purchase designs?",
        answer: "To purchase credits, navigate to the paywall section, select your favorite plan, and proceed to checkout to complete your purchase."
    },
    {
        question: "What results should I expect?",
        answer: "You can expect high-quality, unique tattoo designs generated by our advanced AI, crafted to meet your specific preferences and style choices."
    },
    {
        question: "How can I view my results?",
        answer: "View your purchased and generated designs on the gallery page. Simply log into your account and navigate to the gallery to see all your saved designs."
    },
    {
        question: "What payment methods do you support?",
        answer: "We currently support PayPal and credit card payments. More payment options will be available soon."
    },
    {
        question: "What if I need more than 100 credits as a business?",
        answer: "If you require more than 100 credits, choose our Pro Business plan."
    },
    {
        question: "How can I cancel my subscription?",
        answer: "Cancel your subscription by visiting your account page and following the cancellation instructions."
    },
    {
        question: "How can I contact support?",
        answer: "Contact us via email at tattoogpt.team@gmail.com. We're here to help!"
    },
    {
        question: "Can I suggest new features?",
        answer: "We welcome your feedback and suggestions! Email us at tattoogpt.team@gmail.com with any ideas or requests for new features."
    },
    {
        question: "What if I encounter a problem with my purchase?",
        answer: "If you experience any issues with your purchase, contact our support team at tattoogpt.team@gmail.com, and we'll resolve it as quickly as possible."
    }
];

const HelpCenterPage = () => {
    const [activeIndex, setActiveIndex] = useState(null);

    const toggleFAQ = index => {
        setActiveIndex(activeIndex === index ? null : index);
    };

    return (
        <>
            <Helmet>
                <title>Help Center | TattooGPT</title>
                <meta name="description" content="Find answers to frequently asked questions about TattooGPT, including how to use our AI-powered tattoo generator, purchase designs, and contact support." />
                <link rel="canonical" href="https://www.tattoogpt.art/help-center" />
                <script type="application/ld+json">
                    {`
                    {
                        "@context": "https://schema.org",
                        "@type": "FAQPage",
                        "mainEntity": ${JSON.stringify(faqData.map((faq) => ({
                        "@type": "Question",
                        "name": faq.question,
                        "acceptedAnswer": {
                            "@type": "Answer",
                            "text": faq.answer
                        }
                    })))}
                    }
                `}
                </script>
            </Helmet>
            <div className="helpCenter">
                <div className="banner"></div>
                <h1>Help Center</h1>
                <p className="intro">Popular Questions</p>

                <div className="faq-section">
                    {faqData.map((item, index) => (
                        <div key={index} className={`faq-item ${activeIndex === index ? 'open' : ''}`}
                             onClick={() => toggleFAQ(index)}
                             aria-expanded={activeIndex === index}>
                            <h3>{item.question} <span className={`arrow ${activeIndex === index ? 'open' : ''}`}>▼</span></h3>
                            {activeIndex === index && <p>{item.answer}</p>}
                        </div>
                    ))}
                </div>

                <div className="contact-section">
                    <p>Thank you for your support as a TattooGPT user. Your experience is important to us. Contact us for
                        more questions, and we will answer as soon as we can.</p>
                    <p>Email: <a href="mailto:TattooGPT.Team@Gmail.com">TattooGPT.Team@Gmail.com</a></p>
                </div>
            </div>
        </>
    );
};

export default HelpCenterPage;
