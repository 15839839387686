import React from 'react';
import {Container, Row, Col, Card} from 'react-bootstrap';
import './ComingSoonPage.css';

const ComingSoonPage = () => {
    return (
        <Container fluid className="coming-soon-page">
            <Row className="justify-content-center align-items-center">

                <Card className="text-center shadow-lg p-4 bg-white rounded">
                    <Card.Body>
                        <Card.Title className="display-4 mb-3">Coming Soon</Card.Title>
                        <Card.Text className="lead mb-4">
                            Create stunning Tattoos using AI Transform Your Ideas into Tattoos
                            <br/><br/>
                            TattooGPT.Art Team
                        </Card.Text>
                    </Card.Body>
                </Card>

            </Row>
        </Container>
    );
};

export default ComingSoonPage;
