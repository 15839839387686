import React, { useState } from 'react';
import './HowToCreateDesignUsingAi.css';
import {Helmet} from "react-helmet"; // Import the CSS file

const HowToCreateDesignUsingAi = () => {
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [modalImageSrc, setModalImageSrc] = useState('');

    const openModal = (src) => {
        setModalImageSrc(src);
        setIsModalOpen(true);
    };

    const closeModal = () => {
        setIsModalOpen(false);
        setModalImageSrc('');
    };

    return (
        <>
        <Helmet>
            <title>How to Create Tattoo Designs Using AI | TattooGPT</title>
            <meta name="description" content="Learn how to create stunning tattoo designs with AI using TattooGPT. Follow our step-by-step guide to turn your ideas into beautiful artwork." />
            <meta name="keywords" content="Tattoo Design, AI Tattoo Creator, How to Create Tattoo, Custom Tattoo Designs, AI Art" />
            <link rel="canonical" href="https://www.tattoogpt.art/How-To-Create-Tattoo-Design-Using-Ai" />
        </Helmet>

        <div className="how-to-create-design">
            <br />
            <br />
            <br />
            <h1 className="how-to-create-design__title">How to Create Tattoo Designs Using AI in Seconds</h1>

            <section className="how-to-create-design__section need">
                <h2 className="how-to-create-design__subtitle">Why Settle for Generic Tattoos?</h2>
                <p>
                    Many people spend hours browsing Pinterest or Instagram, searching for the perfect tattoo design.
                    Often, they end up frustrated with generic designs that don’t truly reflect their personality or
                    story.
                </p>
                <img
                    src="https://ai-tattoo-website-images.s3.amazonaws.com/Screenshot+2024-10-19+at+11.38.27.png"
                    alt="Generic vs. Custom Tattoos"
                    className="how-to-create-design__image"
                    onClick={() => openModal("https://ai-tattoo-website-images.s3.amazonaws.com/Screenshot+2024-10-19+at+11.38.27.png")}
                />
                <a href="/" className="cta-button">Get Started Now!</a>
            </section>

            <section className="how-to-create-design__section solution">
                <h2 className="how-to-create-design__subtitle">Introducing TattooGPT – Your AI Tattoo Designer!</h2>
                <p>TattooGPT uses advanced artificial intelligence to generate custom tattoo designs in seconds.</p>
                <ol className="how-to-create-design__list">
                    <li><strong>Describe Your Idea:</strong> Simply input your tattoo concept in a few words.</li>
                    <li><strong>Generate Your Design:</strong> Our AI processes your input and creates unique designs
                        tailored just for you.
                    </li>
                    <li><strong>Review and Customize:</strong> Choose your favorite design and make adjustments to fit
                        your vision.
                    </li>
                </ol>
                <img
                    src="https://ai-tattoo-website-images.s3.amazonaws.com/Screenshot+2024-10-19+at+11.39.55.png"
                    alt="User Interface"
                    className="how-to-create-design__image"
                    onClick={() => openModal("https://ai-tattoo-website-images.s3.amazonaws.com/Screenshot+2024-10-19+at+11.39.55.png")}
                />
                <a href="/" className="cta-button">Get Started Now!</a>
            </section>

            <section className="how-to-create-design__section benefits">
                <h2 className="how-to-create-design__subtitle">Why Choose TattooGPT?</h2>
                <ul className="how-to-create-design__list">
                    <li><strong>Save Time:</strong> No more scrolling for hours; get your design in seconds!</li>
                    <li><strong>Personalized Experience:</strong> Designs created just for you, reflecting your individuality.</li>
                    <li><strong>Easy to Use:</strong> User-friendly interface that anyone can navigate.</li>
                    <li><strong>Share with Friends:</strong> Instantly share your designs with friends and family.</li>
                </ul>
                <img
                    src="https://ai-tattoo-website-images.s3.amazonaws.com/Screenshot+2024-10-19+at+11.43.40.png"
                    alt="Happy Users with Tattoos"
                    className="how-to-create-design__image"
                    onClick={() => openModal("https://ai-tattoo-website-images.s3.amazonaws.com/Screenshot+2024-10-19+at+11.43.40.png")}
                />
            </section>

            <section className="how-to-create-design__section cta" id="cta">
                <h2 className="how-to-create-design__subtitle">Ready to Transform Your Ideas into Stunning Tattoos?</h2>
                <p>Join a community of tattoo lovers who have discovered the power of AI in tattoo design.</p>
                <a href="/" className="cta-button">Start Designing Now!</a>
                <img
                    src="https://ai-tattoo-website-images.s3.amazonaws.com/Screenshot+2024-10-19+at+11.45.03.png"
                    alt="TattooGPT User Testimonials"
                    className="how-to-create-design__image"
                    onClick={() => openModal("https://ai-tattoo-website-images.s3.amazonaws.com/Screenshot+2024-10-19+at+11.45.03.png")}
                />
            </section>

            {isModalOpen && (
                <div className="how-to-create-design_modal" onClick={closeModal}>
                    <span className="how-to-create-design_close" onClick={closeModal}>&times;</span>
                    <img className="how-to-create-design_modal-content" src={modalImageSrc} alt="TattooGPT Large view" />
                </div>
            )}
        </div>
        </>
    );
};

export default HowToCreateDesignUsingAi;
