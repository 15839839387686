import React, {useEffect, useState} from 'react';
import {useLogin} from "../UserAutentication/LoginProvider";
import axios from "axios";
import {Spinner, Card} from "react-bootstrap";
import './HealthCheckPage.css'

const HealthCheckPage = () => {

    const {isLoggedIn} = useLogin();
    const [healthCheckData, setHealthCheckData] = useState(null);
    const [isLoading, setIsLoading] = useState(false);

    const fetchHealthCheckData = async () => {
        setIsLoading(true);
        try {
            const response = await axios.get(process.env.REACT_APP_BASE_SERVER_URL + '/api/v1/system/getHealthCheckData', {
                withCredentials: true,
                params: {isLoggedIn}
            });

            if (response.status === 200) {
                setHealthCheckData(response.data);
            } else {
                console.warn('fetchHealthCheckData, Unexpected response status:', response.status);
            }
        } catch (error) {
            console.error('Unexpected error fetching health check data, AccountId ' + isLoggedIn);
        } finally {
            setIsLoading(false);
        }
    };

    useEffect(() => {
        if (isLoggedIn) {
            fetchHealthCheckData();
        }
    }, [isLoggedIn]);

    if (isLoading) {
        return (
            <div className="d-flex justify-content-center align-items-center" style={{height: '100vh'}}>
                <Spinner animation="border" role="status">
                    <span className="visually-hidden">Loading...</span>
                </Spinner>
            </div>
        );
    }

    if (!healthCheckData) {
        return null;
    }

    // Conditional rendering of smiley/sad face
    const smiley = '🎉';
    const sadFace = '😞';

    return (
        <div className="healthCheckPage">
            <Card className="p-4 shadow">
                <Card.Body>
                    <h2 className="text">Health Check</h2>
                    <hr/>

                    {/* General Info Section */}
                    <h4>General</h4>
                    <ul>
                        <li>Total Users: {healthCheckData.totalUsers}</li>
                        <li>Total Purchases: {healthCheckData.totalPurchases}</li>
                        <li>Total Feedbacks: {healthCheckData.totalFeedbacks}</li>
                    </ul>

                    {/* Today Info Section */}
                    <h4>Today</h4>
                    <ul>
                        <li>New Users
                            Today: {healthCheckData.newUsersToday} {healthCheckData.newUsersToday > 0 ? smiley : sadFace}</li>
                        <li>Purchases
                            Today: {healthCheckData.purchasesAmountToday} {healthCheckData.purchasesAmountToday > 0 ? smiley : sadFace}</li>
                        <li>Feedbacks
                            Today: {healthCheckData.feedbacksCountToday} {healthCheckData.feedbacksCountToday > 0 ? smiley : sadFace}</li>
                    </ul>
                </Card.Body>
            </Card>
        </div>
    );
};

export default HealthCheckPage;
