import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import { PayPalScriptProvider, PayPalButtons, FUNDING } from "@paypal/react-paypal-js";
import Modal from 'react-modal';
import { useLogin } from "../UserAutentication/LoginProvider";
import GoogleLoginButtonLogic from "../UserAutentication/GoogleLoginButtonLogic";
import "./CheckoutPage.css";

const CheckoutPage = ({ product }) => {
    const { isLoggedIn } = useLogin();
    const navigate = useNavigate();
    const [isReady, setIsReady] = useState(false);
    const [showCheckoutModal, setShowCheckoutModal] = useState(false); // Modal state

    const searchParams = new URLSearchParams(window.location.search);
    const isSandbox = searchParams.get('isSandbox') === '1';

    const clientId = isSandbox
        ? process.env.REACT_APP_PAYPAL_CLIENT_ID_SANDBOX
        : process.env.REACT_APP_PAYPAL_CLIENT_ID_LIVE;

    useEffect(() => {
        if (clientId) {
            setIsReady(true);
        } else {
            console.error("PayPal Client ID is missing.");
        }
    }, [clientId]);

    const handleApproveOneTime = async (data, actions) => {
        try {
            const details = await actions.order.capture();
            const response = await axios.post(
                `${process.env.REACT_APP_BASE_SERVER_URL}/api/v1/paypal-transaction-complete`,
                {
                    orderID: data.orderID,
                    payerID: data.payerID,
                    accountId: isLoggedIn,
                    productId: product.productId,
                    price: product.finalPrice,
                    planType: product.billingMode,
                },
                {
                    headers: { 'Content-Type': 'application/json' },
                    withCredentials: true,
                }
            );

            if (response.status === 200) {
                navigate('/thank-you', {
                    state: {
                        details,
                        productId: product.productId,
                        price: product.finalPrice,
                        productName: product.productName,
                    },
                });
            } else {
                console.error('Error during transaction: Unexpected response status');
            }
        } catch (error) {
            console.error('Error during transaction:', error);
        }
    };

    const styles = {
        layout: 'vertical',
        shape: 'rect',
        label: 'pay',
        tagline: false,
    };

    return (
        <div>
            {!isLoggedIn && (
                <div className='checkout-login-container'>
                    <GoogleLoginButtonLogic />
                </div>
            )}
            {isLoggedIn && isReady && product && (
                <>
                <div className='checkout-payment-container'>
                    {/* Main Checkout Button */}
                    <button
                        className="checkout-button"
                        onClick={() => setShowCheckoutModal(true)}
                    >
                        Continue
                    </button>


                    {/* Checkout Modal */}
                    <Modal
                        isOpen={showCheckoutModal}
                        onRequestClose={() => setShowCheckoutModal(false)}
                        contentLabel="Checkout Payment"
                        className="checkout-modal"
                        overlayClassName="modal-overlay"
                    >
                        <h5 className="checkout-model-title">Choose Your Payment Method</h5>

                        {/* PayPal Button */}
                        <PayPalScriptProvider options={{ "client-id": clientId, currency: 'USD' }}>
                            <PayPalButtons
                                fundingSource={FUNDING.PAYPAL}
                                style={styles}
                                createOrder={(data, actions) => actions.order.create({
                                    purchase_units: [{
                                        amount: {
                                            value: product.finalPrice.toFixed(2),
                                            currency_code: 'USD',
                                        },
                                        description: `AI Credits - TattooGPT.Art, Product Id: ${product.productId}`,
                                    }],
                                    application_context: {
                                        shipping_preference: 'NO_SHIPPING',
                                    },
                                })}
                                onApprove={async (data, actions) => {
                                    await handleApproveOneTime(data, actions);
                                    setShowCheckoutModal(false); // Close the modal on success
                                }}
                            />
                            <PayPalButtons
                                fundingSource={FUNDING.CARD}
                                style={styles}
                                createOrder={(data, actions) => actions.order.create({
                                    purchase_units: [{
                                        amount: {
                                            value: product.finalPrice.toFixed(2),
                                            currency_code: 'USD',
                                            user_action: 'PAY_NOW'
                                        },
                                        description: `AI Credits - TattooGPT.Art, Product Id: ${product.productId}`
                                    }],
                                    application_context: {
                                        shipping_preference: 'NO_SHIPPING'
                                    }
                                })}
                                onApprove={async (data, actions) => {
                                    await handleApproveOneTime(data, actions);
                                }}
                            />
                        </PayPalScriptProvider>




                        <button
                            className="close-modal-button"
                            onClick={() => setShowCheckoutModal(false)}
                        >
                            Close
                        </button>
                    </Modal>
                </div>
                </>
            )}
        </div>
    );
};

export default CheckoutPage;
