import React, { useState } from 'react';
import { Container, Row, Col, Card, Button, Form, Alert } from 'react-bootstrap';
import axios from 'axios';

import './FeedbackPage.css';
import { useLogin } from "../UserAutentication/LoginProvider";
import LoginPage from "../UserAutentication/LogInPage";

const FeedbackPage = () => {
    const { isLoggedIn } = useLogin();
    const [feedback, setFeedback] = useState('');
    const [successMessage, setSuccessMessage] = useState('');
    const [errorMessage, setErrorMessage] = useState('');

    const sanitizeInput = (input) => {
        // Define a regular expression for allowed characters
        const isValid = /^[a-zA-Z0-9 .,]*$/.test(input);
        if (!isValid) {
            throw new Error("Input contains invalid characters.");
        }

        // Escape any potentially harmful characters for additional security
        const escapedInput = input
            .replace(/&/g, "&amp;")
            .replace(/</g, "&lt;")
            .replace(/>/g, "&gt;")
            .replace(/"/g, "&quot;")
            .replace(/'/g, "&#039;");

        return escapedInput;
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        setSuccessMessage(''); // Clear previous success message
        setErrorMessage('');   // Clear previous error message

        if (feedback.trim().length === 0) {
            setErrorMessage('Feedback cannot be empty.');
            return;
        }

        try {
            const escapedFeedback = sanitizeInput(feedback); // Correctly apply sanitized input

            const response = await axios.post(
                `${process.env.REACT_APP_BASE_SERVER_URL}/api/v1/feedback/sendFeedback`,
                { feedbackMessage: escapedFeedback }, // Use sanitized feedback here
                { withCredentials: true }
            );

            if (response.status === 200) {
                setSuccessMessage('Thank you for your feedback!');
                setFeedback('');
            } else {
                setErrorMessage('There was an issue submitting your feedback. Please try again.');
            }
        } catch (error) {
            if (error.message === "Input contains invalid characters.") {
                setErrorMessage("Your feedback contains invalid characters. Only letters, numbers, spaces, commas, and periods are allowed.");
            } else {
                setErrorMessage('Error submitting feedback. Please try again later.');
            }
        }
    };

    return (
        <>
            {!isLoggedIn && <LoginPage />}
            <Container fluid className="feedback-page">
                <Row className="justify-content-center align-items-center">
                    <Col md={8}>
                        <Card className="text-left shadow-lg p-4 bg-dark text-white rounded">
                            <Card.Body>
                                <Card.Title className="display-5 mb-3">💬 We Value Your Feedback</Card.Title>
                                <Card.Text>
                                    As a new startup, your insights are invaluable in helping us grow. Tell us what you
                                    think about our design, features, AI results, pricing, or anything else!
                                </Card.Text>

                                {successMessage && <Alert variant="success">{successMessage}</Alert>}
                                {errorMessage && <Alert variant="danger">{errorMessage}</Alert>}

                                <Form onSubmit={handleSubmit}>
                                    <Form.Group controlId="feedback">
                                        <Form.Label>Your Feedback (up to 30 words)</Form.Label>
                                        <Form.Control
                                            as="textarea"
                                            rows={3}
                                            maxLength={150}
                                            placeholder="Write your feedback here..."
                                            value={feedback}
                                            onChange={(e) => setFeedback(e.target.value)}
                                            required
                                        />
                                    </Form.Group>
                                    <Button type="submit" variant="primary" className="mt-3">
                                        Submit Feedback
                                    </Button>
                                </Form>
                            </Card.Body>
                        </Card>
                    </Col>
                </Row>
            </Container>
        </>
    );
};

export default FeedbackPage;
