import React, {useState, useEffect} from 'react';
import axios from 'axios';
import 'bootstrap/dist/css/bootstrap.min.css';
import './Generate.css';
import OutOfCreditPage from "../UserCredit/OutOfCreditPage";
import LoadingSpinner from './LoadingSpinner';
import GenerateForm from './GenerateForm';
import GenerateContent from './GenerateContent';
import {useLogin} from "../UserAutentication/LoginProvider";
import LoginPage from "../UserAutentication/LogInPage";
import {Helmet} from "react-helmet";

const Generate = () => {
    const {isLoggedIn} = useLogin();
    const {hasActiveSubscription} = useLogin();
    const [prompt, setPrompt] = useState('');
    const [bodypart, setBodypart] = useState('wrist');
    const [size, setSize] = useState('small');
    const [colorStyle, setColorStyle] = useState('black and white');
    const [gender, setGender] = useState('female');
    const [hasOneCredit, setHasOneCredit] = useState(true);
    const [resultsAmount, setResultsAmount] = useState(null); //can use hasActiveSubscription
    const [generateUrlsResults, setGenerateUrlsResults] = useState('');
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(false);
    const [isOutOfCredit, setIsOutOfCredit] = useState(null);
    const [showForm, setShowForm] = useState(true);
    const [isMobile, setIsMobile] = useState(false);

    useEffect(() => {
        if (isLoggedIn) {
            checkCredit();
        }
        const handleResize = () => setIsMobile(window.innerWidth <= 600);
        window.addEventListener('resize', handleResize);
        handleResize();
        return () => window.removeEventListener('resize', handleResize);
    }, [isLoggedIn]);

    const checkCredit = async () => {
        try {
            const response = await axios.get(process.env.REACT_APP_BASE_SERVER_URL + '/api/v1/users-credit/fetch-user-credit', {
                withCredentials: true // Automatically includes HttpOnly cookies
            });

            if (response.status === 200) {
                setIsOutOfCredit(response.data.creditAmount < 1);
                setHasOneCredit(response.data.creditAmount===1)
                setResultsAmount(response.data.creditAmount === 1 ?'1':'2');

            } else {
                console.warn('checkCredit Unexpected response status:', response.status);
                setIsOutOfCredit(true);
            }
        } catch (error) {
            console.error('Error fetching user credit:', error);
            setIsOutOfCredit(true);
        }
    };

    const handleSubmit = async (event) => {
        event.preventDefault(); // Prevent default form submission behavior
        await checkCredit(); // Ensure credit is checked before generating

        if (isOutOfCredit) {
            console.error('Error generating image: user out of credit.');
            return;
        }

        setLoading(true);
        setError(false);

        try {
            const response = await axios.post(process.env.REACT_APP_BASE_SERVER_URL + '/api/v1/ai/generate-image-by-prompt', null, {
                params: {
                    isLoggedIn: isLoggedIn.trim(),
                    prompt: prompt.trim(),
                    bodypart: bodypart.trim(),
                    size: size.trim(),
                    colorStyle: colorStyle.trim(),
                    gender: gender.trim(),
                    resultsAmount: resultsAmount.trim()
                },
                withCredentials: true,
                timeout: 250000
            });

            if (response.status === 200) {
                setGenerateUrlsResults(response.data);
                if (isMobile) setShowForm(false);
            } else {
                console.error('Unexpected response status on generating image');
            }
        } catch (error) {
            setError(true);
            console.error('Error generating image. AccountId:', isLoggedIn);
        } finally {
            setLoading(false);
        }
    };

    if (!isLoggedIn) {
        return <LoginPage/>;
    }

    if (isOutOfCredit === null) {
        return <LoadingSpinner/>;
    }

    if (isOutOfCredit) {
        return <OutOfCreditPage/>;
    }

    return (
        <>
            <Helmet>
                <title>Generate Your Tattoo | TattooGPT</title>
                <meta name="description" content="Create stunning, custom tattoos in seconds using TattooGPT's advanced AI technology. Simply describe your tattoo idea and watch it come to life!" />
                <meta name="keywords" content="Tattoo, AI, Tattoo Generator, Custom Tattoos, Design Your Tattoo" />
                <link rel="canonical" href="https://www.tattoogpt.art/Generate" />
            </Helmet>
        <div className="generate-page">
            {(!isMobile || (showForm && !loading)) && (
                <GenerateForm
                    handleSubmit={handleSubmit}
                    prompt={prompt}
                    setPrompt={setPrompt}
                    bodypart={bodypart}
                    setBodypart={setBodypart}
                    size={size}
                    setSize={setSize}
                    colorStyle={colorStyle}
                    setColorStyle={setColorStyle}
                    gender={gender}
                    setGender={setGender}
                    resultsAmount={resultsAmount}
                    setResultsAmount={setResultsAmount}
                    loading={loading}
                    hasOneCredit ={hasOneCredit}
                />
            )}
            <GenerateContent
                error={error}
                loading={loading}
                generateUrlsResults={generateUrlsResults}
                isMobile={isMobile}
                showForm={showForm}
                setShowForm={setShowForm}
                isLoggedIn={isLoggedIn}
            />
        </div>
        </>
    );

};

export default Generate;
