import React, {useState, useEffect} from 'react';
import './CookieConsentBanner.css';

//CookieConsent banner
const CookieConsentBanner = () => {
    const [showBanner, setShowBanner] = useState(false);

    useEffect(() => {
        const cookiesAccepted = localStorage.getItem('cookiesAccepted');
        if (!cookiesAccepted) {
            setShowBanner(true);
        }
    }, []);

    const acceptCookies = () => {
        localStorage.setItem('cookiesAccepted', 'true');
        setShowBanner(false);
    };

    const closeBanner = () => {
        setShowBanner(false);
    };

    if (!showBanner) {
        return null;
    }

    return (
        <div className="cookie-consent-banner">
            <button onClick={closeBanner} className="close-button">
                &#x2715;
            </button>
            <p>
                We use cookies to improve your experience on our site. By using our site, you agree to our use of
                cookies. <a href="/privacy-policy">Learn more</a>
            </p>
            <button onClick={acceptCookies} className="cookie-consent-button">
                I Accept
            </button>
        </div>
    );
};

export default CookieConsentBanner;
