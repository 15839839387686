// src/hooks/useApiStatus.js
import {useEffect, useState} from 'react';
import axios from 'axios';
import {useNavigate} from "react-router-dom";

const GetServerStatus = (baseURL) => {
    const [isApiDown, setIsApiDown] = useState(false);
    const navigate = useNavigate();

    useEffect(() => {
        const checkApiStatus = async () => {
            try {
                await axios.get(`${process.env.REACT_APP_BASE_SERVER_URL}/api/v1/server/server-status`);
                setIsApiDown(false);
            } catch (error) {
                setIsApiDown(true);
            }
        };

        checkApiStatus();
    }, [navigate]);

    return isApiDown;
};


export default GetServerStatus;
