import React, {useState, useEffect} from 'react';
import axios from 'axios';
import {useNavigate, useLocation} from 'react-router-dom';
import './ApplyCoupon.css';
import LoginPage from "../../UserAutentication/LogInPage";
import {useLogin} from "../../UserAutentication/LoginProvider";

const ApplyCoupon = () => {
    const [couponCode, setCouponCode] = useState('');
    const [message, setMessage] = useState('');
    const [isCouponApplied, setIsCouponApplied] = useState(false);
    const navigate = useNavigate();
    const {isLoggedIn} = useLogin();
    const location = useLocation();

    // Function to extract coupon code from URL and set it in state
    useEffect(() => {
        const params = new URLSearchParams(location.search);
        const codeFromUrl = params.get('code');
        const regex = /^[a-zA-Z0-9]*$/;

        // Check if the code exists in the URL and is valid (letters and numbers only)
        if (codeFromUrl && regex.test(codeFromUrl)) {
            setCouponCode(codeFromUrl.toUpperCase());
        }
    }, [location]);

    // Regex for alphanumeric (only letters and numbers allowed)
    const handleInputChange = (e) => {
        const regex = /^[a-zA-Z0-9]*$/;
        if (regex.test(e.target.value)) {
            setCouponCode(e.target.value.toUpperCase());
        }
    };

    // Submit coupon code
    const applyCoupon = async () => {
        try {
            const response = await axios.post(process.env.REACT_APP_BASE_SERVER_URL + '/api/v1/coupons/applyCoupon', {couponCode}, {
                withCredentials: true
            });

            if (response.status === 200) {
                setMessage('Coupon applied successfully!');
                setIsCouponApplied(true);
            }
        } catch (error) {
            setMessage('Invalid coupon or coupon expired.');
        }
    };

    if (!isLoggedIn) {
        return <LoginPage/>;
    }

    return (
        <div className="apply-coupon-container">
            <div className="apply-coupon-background">
                <div className="apply-coupon-card">
                    {!isCouponApplied && (
                        <div className="apply-coupon-insert-part">
                            <h1 className="apply-coupon-title">Apply Coupon 🎁</h1>
                            <p className="apply-coupon-text">
                                Enter your coupon code below to unlock exclusive tattoo generation offers.
                            </p>

                            {/* Input Field */}
                            <input
                                type="text"
                                placeholder="Enter coupon code"
                                value={couponCode}
                                onChange={handleInputChange}
                                maxLength="16"
                                className="coupon-input"
                            />

                            {/* Apply Coupon Button */}
                            <button className="apply-coupon-button" onClick={applyCoupon} disabled={!couponCode}>
                                Apply Coupon 🎉
                            </button>
                        </div>
                    )}

                    {/* Success or Error Message */}
                    {message &&
                        <p className={`apply-coupon-message ${isCouponApplied ? 'success' : 'error'}`}>{message}</p>}

                    {/* Generate Tattoo Button (Visible only when coupon is successfully applied) */}
                    {isCouponApplied && (
                        <button className="generate-tattoo-button" onClick={() => navigate('/generate')}>
                            Generate Tattoo 🌟
                        </button>
                    )}
                </div>
            </div>
        </div>
    );
};

export default ApplyCoupon;
