import React, {useEffect} from 'react';
import {useNavigate} from 'react-router-dom';
import axios from 'axios';
import {useLogin} from "./LoginProvider";

/**
 * isLoggedin Validation:
 * (the validate api call is running for every navigate)
 * user in the website
 *  as he enter any page i run api/v1/users/validate api call,
 * that verify if he has token in the ATUS cookie,
 * if he has one i decrypt it to account id and verify its in the users db, (belong to existing user)
 *  if all good i return the account id back to the FE
 * FE assign to state isLoggedin
 * Now user can use pages that are for logged in user only.
 *  (api call is whitelisted because user could be guest without token)
 */
const IsLoggedInUserCheck = () => {
    const {isLoggedIn, setIsLoggedIn} = useLogin();
    const {hasActiveSubscription, setHasActiveSubscription} = useLogin(null);
    const navigate = useNavigate();

    useEffect(() => {
        const checkLogin = async () => {
            try {
                const response = await axios.get(process.env.REACT_APP_BASE_SERVER_URL+'/api/v1/users/val', {
                    withCredentials: true
                });

                if (!response.data) {
                    console.log("validation failed, logging out this user ");
                    setIsLoggedIn(null);
                } else {
                    // console.log("using cookie validation: setting logged in user as response: " + response.data.userInfo.accountId);
                    setIsLoggedIn(response.data.loggedIn);
                    if (response.data.subscriptionStatus != null) {
                        console.log("setting subscription to ", response.data.subscriptionStatus === "ACTIVE");
                        setHasActiveSubscription(response.data.subscriptionStatus === "ACTIVE");
                    }
                }

            } catch (error) {
                console.error('Error during User login validation, logging out this user error:');
                setIsLoggedIn(null);
            }
        };

        checkLogin();

    }, [navigate]);


    return (
        <div>
            {/* User is valid and logged in */}
        </div>
    );
};

export default IsLoggedInUserCheck;