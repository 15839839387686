import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap-icons/font/bootstrap-icons.css';
import Button from 'react-bootstrap/Button';
import Container from "react-bootstrap/Container";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import {Link} from 'react-router-dom';
import './TopNavBar.css';
import GoogleLoginButtonLogic from "./UserAutentication/GoogleLoginButtonLogic";
import {useLogin} from "./UserAutentication/LoginProvider";
import React, {useState, useRef, useEffect} from "react";
import AccessibilityTools from "./Accessibility/AccessibilityTools";

const Header = () => {
    const {isLoggedIn} = useLogin();
    const [expanded, setExpanded] = useState(false);
    const navbarRef = useRef(null);

    const handleToggle = () => setExpanded(!expanded);
    const handleClose = () => setExpanded(false);

    const handleClickOutside = (event) => {
        if (navbarRef.current && !navbarRef.current.contains(event.target)) {
            handleClose();
        }
    };

    useEffect(() => {
        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);

    return (
        <Navbar bg="dark" variant="dark" expand="lg" className="fixed-top" expanded={expanded} ref={navbarRef}>
            <Container>
                <Link to={'/'} style={{textDecoration: 'none'}} onClick={handleClose}>
                    <Navbar.Brand style={{color: 'white', display: 'flex', alignItems: 'center'}}>
                        <img
                            src="https://ai-tattoo-website-images.s3.amazonaws.com/icon_gold.webp"
                            alt="Tattoo GPT design"
                            className="logo-icon"
                        />
                        <div className="logo-text">
                            <span className="main-title">TattooGPT.Art</span>
                            <span className="subtitle">Generate Perfect Tattoos</span>
                        </div>
                    </Navbar.Brand>
                </Link>

                <div className="mobile-out-nav">
                    <AccessibilityTools/>
                </div>

                <Navbar.Toggle aria-controls="navbarScroll" onClick={handleToggle}/>
                <Navbar.Collapse id="navbarScroll">


                    {/* Different rendering for mobile and desktop */}

                    {window.innerWidth <= 991 ? (
                        <>
                            <div className="right-side-nav">
                                <Nav className="me-auto my-1 my-lg-0 justify-content-start"
                                     style={{maxHeight: '100vh', width: '50%'}} navbarScroll>
                                </Nav>

                                <div className="mobile-right-side-nav">
                                    <Link to="/Generate" className="nav-link custom-link first-menu-link"
                                          onClick={handleClose}>Generate 🚀</Link>
                                    <Link to="/MyGallery" className="nav-link custom-link"
                                          onClick={handleClose}>Gallery 🖼️</Link>
                                    {isLoggedIn && (
                                        <Link to="/MyAccount" className="nav-link custom-link"
                                              onClick={handleClose}>Account 👤</Link>
                                    )}
                                    {isLoggedIn && (
                                        <Link to="/Feedback" className="nav-link custom-link"
                                              onClick={handleClose}>Report issue 💬</Link>
                                    )}
                                    {!isLoggedIn && (
                                        <Link to="/Login?redirect=true" className="nav-link custom-link"
                                              onClick={handleClose}>Login/Signup ✅</Link>
                                    )}
                                </div>
                                <div className="accessibility-tools-mobile">
                                    <AccessibilityTools/>
                                </div>
                            </div>
                        </>
                    ) : (
                        <>
                            <Nav className="me-auto my-1 my-lg-0" style={{maxHeight: '100vh'}} navbarScroll>
                                <Link to="/Generate" className="nav-link custom-link"
                                      onClick={handleClose}>Generate 🚀</Link>
                                <Link to="/MyGallery" className="nav-link custom-link"
                                      onClick={handleClose}>Gallery 🖼</Link>
                                {isLoggedIn &&
                                    <Link to="/MyAccount" className="nav-link custom-link"
                                          onClick={handleClose}>Account 👤</Link>
                                }
                                {isLoggedIn &&
                                    <Link to="/Feedback" className="nav-link custom-link"
                                          onClick={handleClose}>Report issue 💬</Link>
                                }

                                {/* Show AccessibilityTools inside the menu on mobile only */}

                                <AccessibilityTools/>

                            </Nav>

                            <div className="right-side-nav">
                                <div className="right-side-nav">
                                    <Link to={'/Generate'} onClick={handleClose}>
                                        <Button className="me-3 my-2 start-now-button">Create Tattoo With AI</Button>
                                    </Link>

                                    {!isLoggedIn && <GoogleLoginButtonLogic/>}
                                </div>
                            </div>
                        </>
                    )}

                </Navbar.Collapse>
            </Container>
        </Navbar>
    );
};

export default Header;
