import React from 'react';
import './PrivacyPolicyPage.css';

const PrivacyPolicyPage = () => {
    return (
        <div className="privacy">
            <h1>Privacy Policy</h1>

            <section className="privacy-section">
                <h4>1. Introduction</h4>
                <p>Your privacy is  important to us. This Privacy Policy outlines the types of information we may collect from you or that you may provide to TattooGPT.Art, and our practices for collecting, using, maintaining, protecting, and disclosing that information. By using TattooGPT.Art, you agree to the terms outlined in this policy, which complies with the General Data Protection Regulation (GDPR), California Consumer Privacy Act (CCPA), and applicable Israeli privacy laws.</p>
            </section>

            <section className="privacy-section">
                <h4>2. Information Collection</h4>
                <p>We only collect personal information necessary to provide our services to you. Information may include, but is not limited to, your name, email address, and profile picture when signing up with Google. All data is collected by fair and lawful means, with your knowledge and consent, and we will inform you of its intended use.</p>
            </section>

            <section className="privacy-section">
                <h4>3. Google Account Integration</h4>
                <p>When signing up with your Google account, you permit us to access and use your name and public profile picture to create a personalized account experience on TattooGPT.Art. This data is used solely for account creation and user interface personalization and is not shared with third parties except as required by law.</p>
            </section>

            <section className="privacy-section">
                <h4>4. Data Use, Retention, and Protection</h4>
                <p>Personal data collected is used solely for delivering and improving our services. We retain personal information only for as long as necessary to fulfill the purposes outlined in this policy, unless a longer retention period is required or permitted by law. Data is protected within commercially reasonable measures to prevent unauthorized access, disclosure, or modification. However, while we implement security measures, no method of transmission over the internet is entirely secure, and we cannot guarantee absolute security.</p>
            </section>

            <section className="privacy-section">
                <h4>5. Information Sharing and Disclosure</h4>
                <p>We do not sell, trade, or otherwise transfer to outside parties your personally identifiable information unless required to do so by law, or in good faith belief that such action is necessary to comply with legal obligations or to protect the rights, property, or safety of TattooGPT.Art, its users, or the public. We may share aggregated or anonymized data with third parties for marketing, advertising, or research purposes.</p>
            </section>

            <section className="privacy-section">
                <h4>6. Data Controller and Processor</h4>
                <p>TattooGPT.Art serves as both the data controller and processor in relation to the personal data processed through our services, in compliance with applicable data protection regulations, including GDPR. As the data controller, we determine the purposes and means of processing personal data, and as the processor, we act on behalf of our users.</p>
            </section>

            <section className="privacy-section">
                <h4>7. External Links</h4>
                <p>Our website may contain links to external sites that are not operated by TattooGPT.Art. We are not responsible for the privacy practices or the content of those third-party sites. We encourage you to review their privacy policies before providing them with any personal information.</p>
            </section>

            <section className="privacy-section">
                <h4>8. Your Rights and Choices</h4>
                <p>You have the right to access, correct, update, or delete the personal information we hold about you by request to out support (see help center page), as well as to restrict or object to its processing in certain circumstances, in accordance with applicable law. If you choose to refuse to provide certain personal information, please note that we may be unable to provide you with some services.</p>
            </section>

            <section className="privacy-section">
                <h4>9. Consent and Acceptance</h4>
                <p>By continuing to use our website, you acknowledge that you have read, understood, and accepted the terms of this Privacy Policy. If you have questions or concerns about our privacy practices, please contact us, and we will address them in accordance with applicable law.</p>
            </section>

            <section className="privacy-section">
                <h4>10. Updates to This Policy</h4>
                <p>We reserve the right to update this Privacy Policy to reflect changes in our practices, services, legal requirements, or other factors. Updates will be posted on this page, and the "Effective Date" at the bottom will indicate when the policy was last revised.</p>
            </section>

            <section className="privacy-section">
                <h4>11. Effective Date</h4>
                <p>This Privacy Policy is effective as of 12.11.24.</p>
            </section>
        </div>
    );
};

export default PrivacyPolicyPage;
