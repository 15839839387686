import React from 'react';
import {Container, Row, Col, Card} from 'react-bootstrap';
import './ServerDownPage.css';

const ServerDownPage = () => {
    return (
        <Container fluid className="server-down-page">
            <Row className="justify-content-center align-items-center">
                <Col md={4} className="mb-4 mb-md-0">
                    <Card className="text-center shadow-lg p-4 bg-white rounded">
                        <Card.Body>
                            <Card.Title className="display-4 mb-3">Will be back soon</Card.Title>
                            <Card.Text className="lead mb-4">
                                We are currently experiencing technical difficulties. Please try again later.
                                <br/><br/>
                                TattooGPT.Art Team
                            </Card.Text>
                        </Card.Body>
                    </Card>
                </Col>
                <Col md={4} className="d-none d-md-block">
                    <img src="https://ai-tattoo-website-images.s3.amazonaws.com/homePageGallery/homepageGallery4.png"
                         alt="TattooGPT Server Down" className="img-fluid"/>
                </Col>
            </Row>
        </Container>
    );
};


export default ServerDownPage;
