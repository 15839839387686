import React, {useState, useRef, useEffect} from 'react';
import './AccessibilityTools.css';
import 'bootstrap-icons/font/bootstrap-icons.css';

const AccessibilityTools = () => {
    const [isMenuOpen, setIsMenuOpen] = useState(false);
    const [isHighContrast, setIsHighContrast] = useState(false);
    const [isColorBlindMode, setIsColorBlindMode] = useState(false); // New state for color-blind mode
    const menuRef = useRef(null);

    const toggleMenu = () => setIsMenuOpen(!isMenuOpen);

    const toggleContrast = () => {
        setIsHighContrast(!isHighContrast);
        document.body.classList.toggle('high-contrast', !isHighContrast);
        setIsMenuOpen(false);
    };

    const toggleColorBlindMode = () => {
        setIsColorBlindMode(!isColorBlindMode);
        document.body.classList.toggle('color-blind-mode', !isColorBlindMode);
        console.log('Color Blind Mode:', !isColorBlindMode); // Debugging
        setIsMenuOpen(false);
    };

    const increaseFontSize = () => {
        document.body.style.fontSize = '1.4rem'; // Adjust as needed
        setIsMenuOpen(false);
    };

    const decreaseFontSize = () => {
        document.body.style.fontSize = '0.8rem'; // Adjust as needed
        setIsMenuOpen(false);
    };

    const resetFontSize = () => {
        document.body.style.fontSize = '1rem'; // Reset to default size
        setIsMenuOpen(false);
    };

    const handleClickOutside = (event) => {
        if (menuRef.current && !menuRef.current.contains(event.target)) {
            setIsMenuOpen(false);
        }
    };

    useEffect(() => {
        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);

    return (
        <div className="accessibility-tools">
            <button className="accessibility-icon" onClick={toggleMenu}>
                <i className="bi bi-universal-access-circle"></i>
            </button>
            {isMenuOpen && (
                <div className="accessibility-menu" ref={menuRef}>
                    <div className="menu-item">
                        <i className="bi bi-brightness-high"></i>
                        <button onClick={toggleContrast}>Toggle Contrast</button>
                    </div>
                    <div className="menu-item">
                        <i className="bi bi-eye"></i>
                        <button onClick={toggleColorBlindMode}>Color Blind Mode</button>
                    </div>
                    <div className="menu-item">
                        <i className="bi bi-textarea-resize"></i>
                        <button onClick={increaseFontSize}>Increase Font Size</button>
                    </div>
                    <div className="menu-item">
                        <i className="bi bi-textarea-resize"></i>
                        <button onClick={decreaseFontSize}>Decrease Font Size</button>
                    </div>
                    <div className="menu-item">
                        <i className="bi bi-arrow-counterclockwise"></i>
                        <button onClick={resetFontSize}>Reset Font Size</button>
                    </div>
                </div>
            )}
        </div>
    );
};

export default AccessibilityTools;
