import React, {useState, useEffect} from 'react';
import axios from 'axios';
import './PaywallPage.css';
import CheckoutPage from "../CheckoutPage/CheckoutPage";
import {useLogin} from "../UserAutentication/LoginProvider";

const PaywallPage = ({title = null}) => {
    const {isLoggedIn} = useLogin();
    const [selectedPlan, setSelectedPlan] = useState('Pro'); // Default to Pro plan
    const [billingCycle, setBillingCycle] = useState('monthly');
    const [products, setProducts] = useState([]);
    const [selectedProduct, setSelectedProduct] = useState(null);
    const PaywallTitle = title == null ? "~50% off! Offer Ends Soon" : title;

    const plans = [
        // {
        //     name: 'Starter',
        //     color: 'black',
        //     credits: 25,
        //     monthlyProductId: 2,
        //     annualProductId: 3,
        // },
        // {
        //     name: 'Starter',
        //     color: 'black',
        //     credits: 25,
        //     monthlyProductId: 2,
        //     annualProductId: 3,
        // },
        {
            name: 'Pro',
            color: '#49b849',
            credits: 100,
            monthlyProductId: 6,
            annualProductId: 7,
        },
        {
            name: 'Premium',
            color: 'blue',
            credits: 450,
            monthlyProductId: 4,
            annualProductId: 5,
        },
    ];

    useEffect(() => {
        fetchProducts();
    }, []);

    useEffect(() => {
        if (products.length > 0) {
            const productId = getProductId();
            const product = getProductById(productId);
            setSelectedProduct(product);
        }
    }, [products, billingCycle, selectedPlan]);

    const fetchProducts = async () => {
        // uncomment to use cache
        // const cachedProducts = localStorage.getItem('products');
        // const expirationTime = localStorage.getItem('products_expiration');
        // const now = new Date().getTime();
        //
        // // Check if products are cached and haven't expired (10 seconds cache)
        // if (cachedProducts && expirationTime && now < expirationTime) {
        //     console.log("Using cached products");
        //     setProducts(JSON.parse(cachedProducts));
        //     return;
        // }

        console.log("Fetching products from the server");
        try {
            const response = await axios.get(process.env.REACT_APP_BASE_SERVER_URL + `/api/v1/products/getProducts`, {
                withCredentials: true
            });
            if (response.status === 200) {
                setProducts(response.data);
                // uncomment to use cache
                // localStorage.setItem('products', JSON.stringify(response.data));
                //
                // // Set cache expiration to 1h seconds (3600000 milliseconds)
                // const expiration = now + 3600000;
                // localStorage.setItem('products_expiration', expiration.toString());
            }
        } catch (error) {
            console.error('Error fetching Products data:', error);
        }
    };

    const getProductById = (productId) => {
        return products.find(product => product.productId === productId);
    };

    const getProductId = () => {
        const plan = plans.find(p => p.name === selectedPlan);
        return billingCycle === 'monthly' ? plan.monthlyProductId : plan.annualProductId;
    };

    const renderPrice = (productId) => {
        const product = getProductById(productId);
        if (!product) return null;

        return (
            <>
                {product.msrpPrice !== product.finalPrice && (
                    <span className="strikethrough">${product.msrpPrice}</span>
                )}
                ${product.finalPrice}/month
            </>
        );
    };

    const getStringWithTotalPrice = () => {
        if (!selectedProduct) return '0.00';
        let totalPrice = selectedProduct.finalPrice;
        if (billingCycle === 'annual') {
            totalPrice = roundToNearestNine((totalPrice * 12));
        }
        return `$${totalPrice}`;
    };

    const roundToNearestNine = (price) => {
        const remainder = price % 10;
        if (remainder !== 9) {
            if (remainder > 4) {
                price = price - remainder + 9;
            } else {
                price = price - remainder - 1;
            }
        }
        return price;
    };

    const getMsrpStringWithStrike = () => {
        if (!selectedProduct) return '';
        let msrpTotalPrice = selectedProduct.msrpPrice.toFixed(2);
        if (billingCycle === 'annual') {
            msrpTotalPrice = (msrpTotalPrice * 12).toFixed(2);
        }

        let finalPrice = selectedProduct.finalPrice.toFixed(2);
        if (billingCycle === 'annual') {
            finalPrice = (finalPrice * 12).toFixed(2);
        }

        if (msrpTotalPrice !== finalPrice) {
            return msrpTotalPrice;
        }

        return '';
    };

    const selectedPlanInfo = plans.find(plan => plan.name === selectedPlan);


    return (
        <div className="paywall">
            <h1 className="paywall-main-title">{PaywallTitle}</h1>

            <div className="plan-switch">
                {plans.map(plan => (
                    <div
                        key={plan.name}
                        className={`switch-button ${selectedPlan === plan.name ? 'selected' : ''}`}
                        onClick={() => setSelectedPlan(plan.name)}
                    >
                        {plan.name}
                    </div>
                ))}
            </div>

            <div className="plans">
                <div
                    className={`plan premium ${selectedPlan.toLowerCase()} ${selectedPlan === 'premium' ? 'selected' : ''}`}>
                    <ul>
                        <li className="first-paywall-benefit">
                            ✅ Create
                            <span style={{
                                color: selectedPlanInfo.color,
                                fontWeight: 'bold',
                                fontSize: 19
                            }}>{' ' + selectedPlanInfo.credits + ' '}</span>
                            <span style={{
                                color: "#ad0ee1",
                                fontWeight: 'bold',
                                fontSize: 18
                            }}>
                            {billingCycle === 'annual' ? "X12 " : ""}</span>
                            tattoo designs
                            {/*TODO: uncomment if u want (per month/year) on the first benefit */}
                            {/*{billingCycle === 'monthly' ? "(per month)" : "(per year)"}*/}
                        </li>
                        <li>✅ Control tattoo Advanced complexity</li>
                        <li>✅ Access all Special ink styles</li>
                        <li>✅ Best resolution for Tattoo downloads</li>
                        <li>✅ Unlimited access to your creations</li>
                        <li>✅ Access our Tattoo ideas directory</li>
                        <li>✅ Enjoy new features and Updates</li>
                    </ul>
                    <div className="billing-options">

                        {/*TODO: to show monthly and annual plans un comment part 1*/}
                        {/*<div className={`billing-option ${billingCycle === 'annual' ? 'selected' : ''}`}*/}
                        {/*     onClick={() => setBillingCycle('annual')}>*/}
                        {/*    <p style={{*/}
                        {/*        //fontWeight: 'bold',*/}
                        {/*        //color: "#6772b6"*/}
                        {/*    }}>Annually</p>*/}
                        {/*    {products.length > 0 && (*/}
                        {/*        <p>{renderPrice(selectedPlanInfo.annualProductId)}</p>*/}
                        {/*    )}*/}
                        {/*    <div className="badge">Best Value ~50% Off</div>*/}
                        {/*</div>*/}
                        {/*<div className={`billing-option ${billingCycle === 'monthly' ? 'selected' : ''}`}*/}
                        {/*     onClick={() => setBillingCycle('monthly')}>*/}
                        {/*    <p style={{*/}
                        {/*        //fontWeight: 'bold',*/}
                        {/*        //color: "#2c4af3"*/}
                        {/*    }}>Monthly</p>*/}
                        {/*    {products.length > 0 && (*/}
                        {/*        <p>{renderPrice(selectedPlanInfo.monthlyProductId)}</p>*/}
                        {/*    )}*/}
                        {/*</div>*/}
                    </div>
                </div>
            </div>
            <div className="total-price">
                <h3>
                    Total price: {getMsrpStringWithStrike() && (
                    <span className="strikethrough">${getMsrpStringWithStrike()}</span>
                )}
                    <span style={{marginLeft: getMsrpStringWithStrike() ? '3px' : '0'}}>
                                {getStringWithTotalPrice()}
                            </span>
                </h3>
            </div>
            {selectedProduct && <CheckoutPage key={`${selectedPlan}-${billingCycle}`} product={selectedProduct}/>}
            {/*TODO: to show monthly and annual plans remove monthly*/}
            <p className="renew-automaticly-text">Renew monthly Automatically, you can cancel any time</p>
        </div>
    );
};

export default PaywallPage;
